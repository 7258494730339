import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withTranslation from 'hoc/withTranslation';
import {
  RETURN_LOCATION_MODE,
  selectTourById,
  setOrders,
  setSolutionJson,
  setTourParameter,
  setUserParam,
  updateOrder,
} from 'actions';
import { v4 } from 'uuid';
import { hasOngoingAsyncRequest } from 'utils/SolutionHelpers';
import { isNumber } from 'lodash';
import {
  isActivityToDisplay,
  isPointDepot,
  isPointReturnLocation,
  isUnassignedJob,
} from '../../../utils/map/MapFeatureFilters';
import { makeMarker } from '../../../utils/map/MapMarkersUtils';
import { createClusterCustomIcon } from '../../../utils/map/MapClusterUtils';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { getLocationAddress } from '../../../utils/GeoCoder';
import { getSafeValue } from '../../../utils/security';
import ActivityMarkers from './ActivityMarkers';
import StartEndLocations from './StartEndLocations';
import StopMarkers from './StopMarkers';
import JobMarkers from './JobMarkers';
import UnselectedMarkers from './UnselectedMarkers';
import UndesiredMarkers from './UndesiredMarkers';

export const DEFAULT_CLUSTER_RADIUS = 50;
export const MIN_CLUSTER_RADIUS = 40;
export const CLUSTER_BREAKPOINT_MAP_ZOOM = 12;

export const ACTIVITY_DEFAULT_CLUSTER_RADIUS = 100;
export const ACTIVITY_MIN_CLUSTER_RADIUS = 20;

const MapMarkers = ({
  mapData,
  map,
  user,
  display,
  routingRoutes,
  tourData,
  routingData,
  isSolution,
  activateTour,
  showUnassigned,
  translations,
  oAuth,
  tourPlanner,
  solution,
  orders,
  helpers: { formatDuration },
}) => {
  const dispatch = useDispatch();
  const removeHighlight =
    useSelector(({ usageContext: stateUsageContext }) => stateUsageContext).currentStep === 4;
  const showIntersections = user.mapSettings.showIntersections;

  const hourOffset = tourPlanner.offset;
  const displayUnselected = isSolution && tourData;

  const groupedDistances = mapData.geo.features.reduce((acc, feature) => {
    const distanceData = feature.properties.distance;
    if (distanceData) {
      const { tourIndex, distance } = distanceData;
      if (tourIndex !== undefined && distance > 0) {
        if (!acc[tourIndex]) {
          acc[tourIndex] = [];
        }
        acc[tourIndex].push(distance);
      }
    }
    return acc;
  }, {});

  const [highlightStop, setHighlightStop] = useState({ id: -1, tourId: 0 });
  const [highlightUnassigned, setHighlightUnassigned] = useState(false);
  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter({ ...parameter, index: solution.show })),
    [dispatch, solution.show],
  );
  const handleSelectTourById = useCallback((data) => dispatch(selectTourById(data)), [dispatch]);
  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);

  const handleSetOrders = useCallback(
    (newOrders) => dispatch(setOrders(newOrders, false, solution.show)),
    [dispatch, solution.show],
  );

  const handleUpdateOrder = useCallback(
    (o, idx, ordIdx) => dispatch(updateOrder(o, idx, ordIdx)),
    [dispatch],
  );

  const onMarkerClick = useCallback(
    (feature, isPlan) => {
      const isUnassigned = isUnassignedJob(feature);
      const tourId = feature.properties.routeId;
      if (isPlan) {
        const jobId = feature.properties.id;
        const indexFound = orders.findIndex((order) => order.InternalID === jobId);
        const currValue = orders[indexFound]?.Highlight;
        orders[indexFound] = {
          ...orders[indexFound],
          Highlight: !currValue ? true : !currValue,
        };
        dispatch(setOrders(orders));
        handleSetSolutionJson({ jsonTriggerVRP: true });
      }

      if (isUnassigned) {
        showUnassigned();
        setHighlightUnassigned((prev) => isUnassigned && !prev);
      }
      if (tourId === undefined) return;

      if (display.selectedTour !== tourId) {
        const tour = getSafeValue(tourData.tours, tourId);
        activateTour(tourId, tour);
        AmplitudeService.log(AMPLITUDE_EVENTS.MAP_MARKER_ACTIVATE_TOUR);
        setHighlightStop({ id: -1, tourId });
      } else {
        const id = isActivityToDisplay(feature)
          ? feature.properties.stopIndex
          : feature.properties.jobOrder;

        setHighlightStop((prev) =>
          prev.id === id || prev.tourId !== tourId ? { id: -1, tourId } : { id, tourId },
        );
      }
    },
    [tourData, activateTour, showUnassigned, orders, dispatch],
  );

  const updateLocation = (grp, pudoId, newLat, newLng) => {
    return grp.map((group) => {
      return {
        ...group,
        pudos: group.pudos.map((pudo) => {
          if (pudo.id === pudoId) {
            return {
              ...pudo,
              places: pudo.places.map((place) => ({
                ...place,
                location: {
                  ...place.location,
                  lat: newLat,
                  lng: newLng,
                },
              })),
            };
          }
          return pudo;
        }),
      };
    });
  };

  const onMarkerDragEnd = useCallback(
    (m, feature) => {
      getLocationAddress(m.getLatLng(), oAuth, user).then((item) => {
        const loc = {
          label: item.address.label,
          value: item.position,
        };
        const isEditing = feature.properties.isEditing;
        const featureID = feature.properties.id;
        const isPickup = feature.properties.isPickup;
        handleSelectTourById({ selectedTour: display.selectedTour, noMapMovement: true });

        if (isPointDepot(feature)) {
          const toSet = { location: loc };
          if (tourPlanner.returnLocationMode === RETURN_LOCATION_MODE.START)
            toSet.returnLocation = loc;
          handleSetTourParameter(toSet);
          if (solution.json) {
            handleSetSolutionJson({ jsonUserChange: 'ordersDepot' });
            handleSetSolutionJson({ jsonTriggerVRP: true });
          }
        } else if (isPointReturnLocation(feature)) {
          handleSetTourParameter({ returnLocation: loc });
          if (solution.json) {
            handleSetSolutionJson({ jsonUserChange: 'ordersEndLoc' });
            handleSetSolutionJson({ jsonTriggerVRP: true });
          }
        } else if (feature.properties.isPudo) {
          let groups = tourPlanner.groups;
          groups = updateLocation(groups, featureID, loc.value.lat, loc.value.lng);

          handleSetTourParameter({ groups });
          handleSetSolutionJson({ jsonTriggerVRP: true });
        } else {
          const orderIndex = orders.findIndex(
            (order) =>
              order.InternalID === featureID &&
              order.Activity === (isPickup ? 'pickup' : 'delivery'),
          );

          if (orderIndex >= 0) {
            orders[orderIndex] = {
              ...(isEditing ? tourPlanner.editedOrder : orders[orderIndex]),
              InternalID: !isEditing ? orders[orderIndex].InternalID : v4(),
              Address: loc.label,
              Latitude: loc.value.lat,
              Longitude: loc.value.lng,
              Activity: feature.properties.types[0],
              Dragged: true,
              TimeCreated: Date.now(),
            };
          }

          if (!isEditing) {
            handleSetOrders(orders);
            handleSetSolutionJson({ jsonTriggerVRP: true });
          } else
            handleSetTourParameter({
              editedOrder: {
                ...tourPlanner.editedOrder,
                ...newOrder,
              },
            });
        }
      });
    },
    [
      oAuth,
      user,
      tourPlanner,
      handleSetTourParameter,
      handleUpdateOrder,
      orders,
      solution.show,
      solution.json,
      handleSetSolutionJson,
      handleSelectTourById,
      display.selectedTour,
    ],
  );

  const makeMapMarkers = useCallback(
    (feature, latlng) => {
      const highlight =
        display.selectedTour === highlightStop.tourId &&
        (feature.properties.jobOrder === highlightStop.id ||
          feature.properties.stopIndex === highlightStop.id);
      return makeMarker(
        feature,
        latlng,
        display,
        onMarkerClick,
        translations,
        onMarkerDragEnd,
        isSolution,
        hourOffset,
        highlight,
        highlightUnassigned,
        hasOngoingAsyncRequest(solution.requests),
        dispatch,
        orders,
        solution,
        formatDuration,
        groupedDistances,
      );
    },
    [
      display,
      translations,
      isSolution,
      hourOffset,
      highlightStop,
      onMarkerClick,
      onMarkerDragEnd,
      highlightUnassigned,
      solution.requests,
      dispatch,
      orders,
      solution,
      groupedDistances,
    ],
  );

  const createClusterIcon = useCallback(
    (cluster) => {
      return createClusterCustomIcon(cluster, highlightStop, map, translations);
    },
    [highlightStop, translations, map, translations],
  );

  useEffect(() => {
    if (removeHighlight) setHighlightUnassigned(false);
  }, [removeHighlight]);

  useEffect(() => {
    const overlapCount = routingRoutes?.features.filter(
      (f) => f?.properties.isUndesired && f.geometry.type === 'Point',
    )[0]?.properties.overlapCount;

    if (user?.overlapCount === undefined && isNumber(overlapCount))
      handleSetUserParam({ overlapCount });
  }, [routingRoutes?.features, user?.overlapCount, handleSetUserParam]);

  return (
    <>
      <UnselectedMarkers
        makeMapMarkers={makeMapMarkers}
        mapData={mapData}
        user={user}
        display={display}
        routingRoutes={routingRoutes}
        displayUnselected={displayUnselected}
        highlightUnassigned={highlightUnassigned}
      />
      <StopMarkers
        makeMapMarkers={makeMapMarkers}
        mapData={mapData}
        user={user}
        display={display}
        routingRoutes={routingRoutes}
        highlightStop={highlightStop}
        createClusterIcon={createClusterIcon}
        isSolution={isSolution}
        routingData={routingData}
      />
      {!!tourData && showIntersections && routingRoutes && (
        <UndesiredMarkers
          makeMapMarkers={makeMapMarkers}
          mapData={mapData}
          user={user}
          display={display}
          routingRoutes={routingRoutes}
          highlightStop={highlightStop}
          createClusterIcon={createClusterIcon}
        />
      )}
      {display.showProblem && (
        <JobMarkers
          makeMapMarkers={makeMapMarkers}
          mapData={mapData}
          user={user}
          display={display}
          routingRoutes={routingRoutes}
          highlightStop={highlightStop}
          createClusterIcon={createClusterIcon}
          isSolution={isSolution}
          routingData={routingData}
        />
      )}
      {user.mapSettings.showStops && (
        <>
          <StartEndLocations
            makeMapMarkers={makeMapMarkers}
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            routingData={routingData}
            isSolution={isSolution}
          />
          <ActivityMarkers
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            highlightStop={highlightStop}
            makeMapMarkers={makeMapMarkers}
            createClusterIcon={createClusterIcon}
          />
        </>
      )}
    </>
  );
};

export default withTranslation(MapMarkers);
