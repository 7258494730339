import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { setOrders, clearOrders, updateOrder, setSolutionJson, setTourParameter } from 'actions';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, isEqual, isNumber, last, map, orderBy } from 'lodash';
import { css } from '@emotion/react';
import { useCookies } from 'react-cookie';
import {
  StyledInfoEllipsis,
  StyledInfoWrapper,
  StyledIcon,
  StyledText,
  StyledSelectInput,
} from '../Global/WizardStyled';
import { getAsset, isProdEnv } from '../../../utils/helpers';
import deleteIcon from '../../../global/img/delete.svg';
import deleteIconDisabled from '../../../global/img/deleteDisabled.svg';
import priorityHighIcon from '../../../global/img/priority-high-black.svg';
import priorityNormalIcon from '../../../global/img/priority-normal-black.svg';
import orderActivityDelivery from '../../../global/img/order-activity-delivery.svg';
import orderActivityPickup from '../../../global/img/order-activity-pickup.svg';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import { getCSVConfigLastDemandLabel } from '../../../utils/csv/customConfig';
import { ORDER_ACTIVITIES, ORDER_PRIORITY } from '../../../utils/csv/config';
import { getSafeValue } from '../../../utils/security';

const {
  primaryBorderColor,
  secondaryBorderColor,
  transparentGreyColor,
  transparentYellowColor,
  transparentBlueColor,
  black,
  primaryTextColor,
  secondaryTextColor,
} = colors;
const { medium, small } = fontSize;

const StyledGridRow = styled.div`
  display: grid;
  grid-template-columns: 1rem 2rem 1fr 1.2fr 1fr 5rem 1rem;

  div:nth-last-of-type(-n + 6) {
    border-bottom: none;
  }
  div {
    text-align: left;
    vertical-align: text-bottom;
  }
  div:nth-last-of-type(6n + 2) {
    text-align: center;
  }
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 1rem 2rem 1fr 1.2fr 1fr 4rem 1rem;

  margin-top: 0;
  border-bottom: 1px solid ${primaryBorderColor};

  & > div {
    text-transform: uppercase;
    font-size: ${small};
    position: relative;
    top: -2px;
    opacity: 0.75;
  }
`;

const EXPORT_TYPES = { LAST: 'last', FIRST: 'first', ORDER: 'order', ANY: 'any' };

const StyledOrder = styled.div(({ isHighPriority, isHighlighted }) => ({
  lineHeight: '3rem',
  padding: '0.5rem 0.4rem 0.5rem 0',
  borderBottom: `1px solid ${secondaryBorderColor}`,
  color: secondaryTextColor,
  fontSize: medium,
  fontWeight: '700',
  backgroundColor: isHighlighted
    ? transparentBlueColor
    : isHighPriority
    ? transparentYellowColor
    : transparentGreyColor,
}));

const StyledOrderName = styled.div({
  color: black,
  lineHeight: '1rem',
  padding: '0.5rem 0',
  div: {
    textAlign: 'left !important',
    width: '6rem',
    display: 'block',
    color: secondaryTextColor,
  },
  'div:last-of-type': {
    color: primaryTextColor,
    fontWeight: 400,
  },
});

const StyledOrderAddress = styled(StyledInfoWrapper)({
  width: '5rem',
  color: secondaryTextColor,
  fontWeight: 400,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledRemoveIconStyle = (disabled) => css`
  background-image: url(${getAsset(disabled ? deleteIconDisabled : deleteIcon)});
  cursor: pointer;
  background-size: 1.2rem;
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  top: 3px;
  opacity: 0.8;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  pointer-events: ${disabled && 'none'};

  &:hover {
    opacity: ${!disabled && 1};
  }
`;

const StyledPriorityHighIcon = css`
  background-image: url(${getAsset(priorityHighIcon)});
  background-position: top;
  opacity: 0.3;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  cursor: pointer;
  margin: 0 0 0.2rem 0.6rem;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledPriorityNormalIcon = css`
  background-image: url(${getAsset(priorityNormalIcon)});
  background-position: bottom;
  opacity: 0.3;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  cursor: pointer;
  margin: 0 0 0.2rem 0.6rem;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledErrorWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  marginTop: '2rem',
  zIndex: 4000,
});

const StyledOrderContent = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction column;
  align-items: center;
  width: 100%;

  & > div:first-of-type {
    flex-grow: 1;
    text-align: right;
  }
`;

const StyledTextLight = styled.strong`
  font-weight: 300;
  margin: 0 1rem 0.25rem 1rem;
  color: ${(props) => (props.contrast ? white : secondaryTextColor)};
  font-size: ${medium};
  height: 1.75rem;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-direction: column;
`;

const StyledInput = styled.input`
  width: 3.9rem;
  border: ${(props) => (props.errorObj ? '1px solid red' : '1px solid #ccc')};
`;

const Order = withTranslation(
  ({
    order,
    index,
    onOrderRemove,
    allowRemove,
    translations,
    hasJsonOrders,
    solIndex,
    handleSetSolutionJson,
  }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const solution = useSelector(({ solution: stateSolution }) => stateSolution);
    const orders = useSelector((state) => state.orders[solution.show]);
    const { orderPriorityLabels, orderActivityLabels } = translations.wizard.orders;
    const { emptyOrder } = translations.wizard.toursDetails;
    const isPriorityOrder = order.Priority === ORDER_PRIORITY.HIGH;
    const highlightedRef = useRef(null);
    const inputRef = useRef();
    const defVal = order?.Position ? EXPORT_TYPES.ORDER : EXPORT_TYPES.ANY;
    const [removeHighlight, setRemoveHighlight] = useState(false);
    const [selectedType, setSelectedType] = useState(defVal);
    const [orderedNumber, setOrderedNumber] = useState();
    const [errorObj, setErrorObj] = useState(false);
    const [position, setPosition] = useState(order.Position);
    const [focus, setFocus] = useState(false);
    const priorityIcon = isPriorityOrder ? StyledPriorityHighIcon : StyledPriorityNormalIcon;
    const priorityLabel = isPriorityOrder ? orderPriorityLabels.high : orderPriorityLabels.normal;
    const priorityText = `${orderPriorityLabels.title}: ${priorityLabel}`;
    const activityIcon =
      order.Activity === ORDER_ACTIVITIES.PICKUP ? orderActivityPickup : orderActivityDelivery;
    const activityLabel =
      getSafeValue(orderActivityLabels, order.Activity) || orderActivityLabels.delivery;
    const handleUpdateOrder = useCallback((o, idx) => dispatch(updateOrder(o, idx)), [dispatch]);
    const handleSetOrders = useCallback(
      (newOrders) => dispatch(setOrders(newOrders, false, solution.show)),
      [dispatch, solution.show],
    );
    const handleSetTourParameter = useCallback(
      (parameter) => dispatch(setTourParameter({ ...parameter, index: solution.show })),
      [dispatch, solution.show],
    );
    const internalID = order.InternalID;
    const lastImport = user.lastImport;
    const csvOrders = lastImport?.filename?.endsWith('.csv') && !isEmpty(lastImport.delimiter);
    const deletableOrder =
      internalID?.startsWith('manual-') || internalID?.startsWith('demo-') || csvOrders;

    const onChangeType = (e) => {
      const value = e.target.value;
      setSelectedType(value);
      if (value === EXPORT_TYPES.ORDER) setFocus(true);

      const currOrderIdx = orders.findIndex((or) => isEqual(or, order));
      orders[currOrderIdx] = {
        ...order,
        Position: value,
      };

      handleSetOrders(orders);
      handleSetSolutionJson({ jsonTriggerVRP: true });
      if (order.Activity === 'pudo') {
        handleSetTourParameter({
          pudoUpdate: { name: order.InternalID, value: e.target.value },
        });
        handleSetSolutionJson({ jsonUserChange: 'pudo' });
      }
    };

    const onChangeOrder = (e) => {
      const newValue = e.target.value;
      if (selectedType === EXPORT_TYPES.ORDER) {
        const value = Number(newValue);
        setOrderedNumber(value);
        setPosition(value);
      }
    };

    const onValueBlur = useCallback(
      (e) => {
        if (e.target.value === '' && selectedType === 'order') {
          setErrorObj(true);
        } else {
          setErrorObj(false);
        }
        if (Number(e.target.value) === order.Position) return;

        const currOrderIdx = orders.findIndex((or) => isEqual(or, order));

        if (currOrderIdx >= 0 && orderedNumber) {
          orders[currOrderIdx] = {
            ...order,
            Position: orderedNumber,
            PositionValue: orderedNumber,
          };
        }

        handleSetOrders(orders);
        handleSetSolutionJson({ jsonTriggerVRP: true });
      },
      [orderedNumber, handleSetOrders, handleSetSolutionJson, order, orders],
    );

    useEffect(() => {
      if (order.Highlight && highlightedRef.current) {
        highlightedRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });

        setTimeout(() => {
          setRemoveHighlight(true);
        }, 1500);
      }
    }, [order.Highlight]);

    useEffect(() => {
      setRemoveHighlight(false);
    }, [order.Highlight]);

    useEffect(() => {
      if (order.Position === EXPORT_TYPES.FIRST) setSelectedType(EXPORT_TYPES.FIRST);
      else if (order.Position === EXPORT_TYPES.LAST) setSelectedType(EXPORT_TYPES.LAST);
      else if (order.Position === EXPORT_TYPES.ANY) setSelectedType(EXPORT_TYPES.ANY);
      else if (isNumber(order.Position)) {
        setSelectedType(EXPORT_TYPES.ORDER);
        setOrderedNumber(order.Position);
        setPosition(order.Position);
      } else setPosition(order.Position);
    }, [order.Position]);

    useEffect(() => {
      const inputElement = inputRef.current;
      if (selectedType === EXPORT_TYPES.ORDER && inputElement && focus) {
        inputElement.focus();
      }
    }, [selectedType, focus]);

    const handleChangeOrderPriority = useCallback(() => {
      const newPriority = order.Priority === ORDER_PRIORITY.HIGH ? undefined : ORDER_PRIORITY.HIGH;
      const newOrder = { ...order, Priority: newPriority };
      handleUpdateOrder(newOrder, solIndex);
      handleSetSolutionJson({ jsonTriggerVRP: true });
    }, [order, solIndex, handleSetSolutionJson, handleUpdateOrder]);

    return (
      <>
        <StyledOrder
          isHighPriority={isPriorityOrder}
          isHighlighted={!removeHighlight && order.Highlight}
        />
        <StyledOrder
          isHighPriority={isPriorityOrder}
          isHighlighted={!removeHighlight && order.Highlight}
        >
          {(allowRemove || deletableOrder) && (
            <StyledIcon
              id={`button-remove-order-${index}`}
              icon={StyledRemoveIconStyle(hasJsonOrders && !deletableOrder && !allowRemove)}
              onClick={() => onOrderRemove(order)}
            />
          )}
        </StyledOrder>
        <StyledOrder
          id={`label-order-name-${index}`}
          isHighPriority={isPriorityOrder}
          isHighlighted={!removeHighlight && order.Highlight}
        >
          <StyledOrderName>
            <StyledInfoEllipsis title={order.Name}>{order.Name}</StyledInfoEllipsis>
            <StyledInfoEllipsis data-test-id="OrderID" title={order.ID}>
              {order.ID}
            </StyledInfoEllipsis>
          </StyledOrderName>
        </StyledOrder>
        <StyledOrder
          id={`label-order-address-${index}`}
          isHighPriority={isPriorityOrder}
          isHighlighted={!removeHighlight && order.Highlight}
        >
          <StyledOrderAddress data-test-id="OrderAddress" title={order.Address}>
            {order.Address}
          </StyledOrderAddress>
        </StyledOrder>
        <StyledOrder
          id={`label-order-demand-${index}`}
          isHighPriority={isPriorityOrder}
          isHighlighted={!removeHighlight && order.Highlight}
        >
          <StyledOrderContent>
            <div title={order.Demand && order.Demand.join(',')}>
              {order.Demand && order.Demand[0]}
              {order.Demand && order.Demand.length > 1 && '...'}
            </div>
            <StyledIcon iconUrl={activityIcon} title={activityLabel} />
            <StyledIcon
              id={`button-order-change-priority-${index}`}
              icon={priorityIcon}
              title={priorityText}
              mini
              onClick={hasJsonOrders && !deletableOrder ? undefined : handleChangeOrderPriority}
            />
          </StyledOrderContent>
        </StyledOrder>
        <StyledOrder
          id={`label-order-position-${index}`}
          isHighPriority={isPriorityOrder}
          isHighlighted={!removeHighlight && order.Highlight}
          ref={order.Highlight ? highlightedRef : null}
        >
          <StyledOrderContent>
            <StyledWrapper>
              <StyledSelectInput
                data-test-id="PositionTypeSelect"
                onChange={onChangeType}
                value={selectedType}
              >
                {order.Activity !== 'pudo' && (
                  <option value={EXPORT_TYPES.ANY} key={EXPORT_TYPES.ANY} disabled={false}>
                    {translations.wizard.orders.orderAny}
                  </option>
                )}
                {order.Activity !== 'pudo' && (
                  <option value={EXPORT_TYPES.ORDER} key={EXPORT_TYPES.ORDER} disabled={false}>
                    {translations.wizard.orders.orderOrdered}
                  </option>
                )}
                <option value={EXPORT_TYPES.FIRST} key={EXPORT_TYPES.FIRST} disabled={false}>
                  {translations.wizard.orders.orderFirst}
                </option>
                <option value={EXPORT_TYPES.LAST} key={EXPORT_TYPES.LAST} disabled={false}>
                  {translations.wizard.orders.orderLast}
                </option>
              </StyledSelectInput>
              {selectedType === 'order' && (
                <StyledInput
                  ref={inputRef}
                  id="input-position"
                  type="number"
                  title={errorObj ? emptyOrder : ''}
                  value={position || ''}
                  checked={false}
                  onChange={onChangeOrder}
                  onBlur={onValueBlur}
                  disabled={false}
                  errorObj={errorObj}
                />
              )}
            </StyledWrapper>
          </StyledOrderContent>
        </StyledOrder>
        <StyledOrder isHighPriority={isPriorityOrder} />
      </>
    );
  },
);

const Orders = ({
  orders,
  allowRemove,
  tourPlanner,
  hasJsonOrders,
  translations: {
    wizard: {
      orders: { ordersHeaders },
    },
    error: { orderPanelError },
    tourPlanner: { jsonApiKey },
  },
}) => {
  const [cookies] = useCookies(['apikey']);

  const dispatch = useDispatch();
  const handleSetOrders = useCallback((o, o1, o2) => dispatch(setOrders(o, o1, o2)), [dispatch]);

  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const handleClearOrders = useCallback((data) => dispatch(clearOrders(data)), [dispatch]);
  const demandLabel = getCSVConfigLastDemandLabel(tourPlanner, ordersHeaders[2]);
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const hasNoApiKey = isProdEnv() && (!cookies.apikey || cookies.apikey === '');
  const sortedOrders = orderBy(orders, ['TimeCreated'], ['desc']);

  const tableHeader = useMemo(
    () => (
      <StyledHeader>
        {map(['', '', ...ordersHeaders, ''], (header, index) => (
          <StyledInfoEllipsis key={index} title={index === 4 ? demandLabel : header}>
            {index === 4 ? demandLabel : header}
          </StyledInfoEllipsis>
        ))}
      </StyledHeader>
    ),
    [ordersHeaders, demandLabel],
  );

  const onOrderRemove = useCallback(
    (order) => {
      handleClearOrders({ index: solution.show });
      const newOrders = orders.filter((o) => o !== order);
      handleSetOrders(newOrders, false, solution.show);
      handleSetSolutionJson({ jsonTriggerVRP: true });
      AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_DELETE_SINGLE);
    },
    [orders, handleClearOrders, handleSetOrders, solution.show, handleSetSolutionJson],
  );

  return (
    <>
      {tableHeader}
      {isEmpty(orders) ? (
        <StyledErrorWrap>
          <StyledText>{hasNoApiKey ? jsonApiKey : orderPanelError}</StyledText>
          <StyledTextLight>{last(solution.requests).error}</StyledTextLight>
        </StyledErrorWrap>
      ) : (
        <div>
          {sortedOrders.map((order, index) => (
            <StyledGridRow key={index}>
              <Order
                index={index}
                key={`order-${index}`}
                order={order}
                onOrderRemove={onOrderRemove}
                allowRemove={allowRemove}
                hasJsonOrders={hasJsonOrders}
                solIndex={solution.show}
                handleSetSolutionJson={handleSetSolutionJson}
              />
            </StyledGridRow>
          ))}
        </div>
      )}
    </>
  );
};

export default withTranslation(Orders);
