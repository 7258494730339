import {
  USER_SET_PARAM,
  USER_SET_COOKIES,
  USER_DISPLAY_COOKIES,
  USER_INCREASE_USAGE,
  RECORD_USAGE_EVENT,
  USAGE_EVENTS,
  SET_UPLOADED_FILE,
  DELETE_UPLOADED_FILE,
} from 'actions';
import { assign, cloneDeep } from 'lodash';
import moment from 'moment';
import { getUrlUserSource } from '../utils/urlHelpers';
import { getStorageValue, setStorageValue, STORAGE_IDS } from '../utils/localStorageHelpers';
import { setLocale } from '../utils/timeFormatHelpers';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../utils/amplitude';
import getInitialLanguage from '../utils/LanguageHelpers';
import { guessDelimiter } from '../utils/csv/helper';
import { isProdEnv } from '../utils/helpers';

const isProd = isProdEnv();

export const PANELS = {
  MAP_SETTINGS: 'mapSettings',
  FEEDBACK_CONTAINER: 'feedbackContainer',
  SOLVER_CONFIG: 'solverConfig',
};

const getDefaultStateMemory = (storage) => {
  if (!storage) return false;
  if (storage.hasStateMemory === undefined) return false;
  return storage.hasStateMemory;
};

const userFromStorage = getStorageValue(STORAGE_IDS.userState);
const sourceFromStorage = userFromStorage && userFromStorage.source;
const sourceFromUrl = getUrlUserSource();
const source = sourceFromUrl || sourceFromStorage || '';
const defaultLanguage = getInitialLanguage();
const cookieFromStorage = getStorageValue(STORAGE_IDS.cookieAgreement, null, true);
const cookieFromStorageVal =
  cookieFromStorage && cookieFromStorage.value && cookieFromStorage.value.siteImprovementApproved;
const cookies = { accept: !!cookieFromStorageVal, display: !cookieFromStorage };
const firstVisitDate = cookieFromStorage ? cookieFromStorage.createdOn : new Date();
const lastVisitDate =
  (userFromStorage && userFromStorage.usage && userFromStorage.usage.lastVisitDate) ||
  firstVisitDate;
const defaultCurrency = 'EUR';
const defaultDistance = 'metric';
const accessLogging = false;
const defaultApiKeyDialogOpened = false;
const defaultPoliticalView = null;
const defaultTpaVersion = { version: 'prod' };
const defaultRequestType = 'automatic';
export const defaultMapSettings = {
  showStops: true,
  showStopClusters: true,
  showJobClusters: true,
  gradientRoutes: false,
  showStraightRouteLines: false,
  showTourDirectionAnimation: false,
  showTourConvexHull: false,
  showTourConcaveHull: { value: 2, enabled: false },
  hideAllRoutes: false,
  satelliteView: false,
  showIntersections: false,
  showOverlaps: false,
  hideVisited: false,
};
const defaultOpenPanel = undefined;
const initialUsage = {
  firstVisitDate,
  lastVisitDate,
  loads: cookieFromStorage && cookieFromStorage.createdOn ? 1 : 0,
  days: 1,
  toursPlanned: 0,
  toursDispatched: 0,
  ordersDispatched: 0,
};
const usage = (userFromStorage && userFromStorage.usage) || initialUsage;
usage.loads += 1;
if (!moment().isSame(lastVisitDate, 'day')) usage.days += 1;
usage.lastVisitDate = new Date();
const hasStoredIsDemo = userFromStorage && userFromStorage.isDemo !== undefined;
if (userFromStorage && !userFromStorage.tpaVersion.version) delete userFromStorage.tpaVersion;
const initialState = {
  language: defaultLanguage,
  isDemo: hasStoredIsDemo ? userFromStorage.isDemo : true,
  isLastOrdersAddedDemo: hasStoredIsDemo ? userFromStorage.isLastOrdersAddedDemo : false,
  useImport: hasStoredIsDemo ? userFromStorage.useImport : false,
  useManual: hasStoredIsDemo ? userFromStorage.useManual : false,
  lastAddedOrdersMode:
    userFromStorage && userFromStorage.lastAddedOrdersMode
      ? userFromStorage.lastAddedOrdersMode
      : undefined,
  isOnline: false,
  location: null,
  cookies,
  source,
  jsonPanelOpen: false,
  jsonTourPanelOpen: false,
  usage,
  hasStateMemory: getDefaultStateMemory(userFromStorage),
  currency:
    userFromStorage && userFromStorage.currency ? userFromStorage.currency : defaultCurrency,
  distance:
    userFromStorage && userFromStorage.distance ? userFromStorage.distance : defaultDistance,
  apiKeyDialogOpened:
    userFromStorage && userFromStorage.apiKeyDialogOpened
      ? userFromStorage.apiKeyDialogOpened
      : defaultApiKeyDialogOpened,
  politicalView:
    userFromStorage && userFromStorage.politicalView
      ? userFromStorage.politicalView
      : defaultPoliticalView,
  accessLogging:
    userFromStorage && userFromStorage.accessLogging
      ? userFromStorage.accessLogging
      : accessLogging,
  tpaVersion:
    isProd && userFromStorage?.tpaVersion?.version === 'custom'
      ? defaultTpaVersion
      : userFromStorage?.tpaVersion || defaultTpaVersion,
  requestType:
    userFromStorage && userFromStorage.requestType
      ? userFromStorage.requestType
      : defaultRequestType,
  mapSettings:
    userFromStorage && userFromStorage.mapSettings
      ? userFromStorage.mapSettings
      : defaultMapSettings,
  openPanel: defaultOpenPanel,
  userQRCode: { isPanelOpen: false },
};
setLocale(initialState.language);
AmplitudeService.setUserParams(initialState);
AmplitudeService.log(AMPLITUDE_EVENTS.PAGE_LOADED);

const storeUserState = (state) => {
  const toStore = {
    language: state.language,
    source: state.source,
    usage: state.usage,
    isDemo: state.isDemo,
    isLastOrdersAddedDemo: state.isLastOrdersAddedDemo,
    useImport: state.useImport,
    useManual: state.useManual,
    lastAddedOrdersMode: state.lastAddedOrdersMode,
    politicalView: state.politicalView,
    hasStateMemory: state.hasStateMemory,
    currency: state.currency,
    distance: state.distance,
    apiKeyDialogOpened: state.apiKeyDialogOpened,
    tpaVersion: state.tpaVersion,
    requestType: state.requestType,
    mapSettings: state.mapSettings,
    accessLogging: state.accessLogging,
  };
  setStorageValue(STORAGE_IDS.userState, toStore);
};

export default (state = cloneDeep(initialState), { payload = {}, type }) => {
  switch (type) {
    case USER_SET_PARAM: {
      if (payload.language) {
        setLocale(payload.language);
        AmplitudeService.log(AMPLITUDE_EVENTS.USER_SET_LANGUAGE, { language: payload.language });
      }
      const newState = assign({}, state, payload);

      AmplitudeService.setUserParams(newState);
      storeUserState(newState);
      return newState;
    }
    case USER_SET_COOKIES: {
      setStorageValue(STORAGE_IDS.cookieAgreement, { siteImprovementApproved: payload });
      const newCookies = assign({}, state.cookies, { accept: payload });
      return assign({}, state, { cookies: newCookies });
    }
    case USER_DISPLAY_COOKIES: {
      const newCookies = assign({}, state.cookies, { display: payload });
      return assign({}, state, { cookies: newCookies });
    }
    case RECORD_USAGE_EVENT: {
      if (payload.event !== USAGE_EVENTS.ORDERS_ADD) return state;

      const newState = assign({}, state);
      const mode = payload.mode;
      newState.isLastOrdersAddedDemo = mode === 'demo';
      newState.lastAddedOrdersMode = mode === 'upload' || mode === 'drop' ? 'import' : mode;
      if (mode !== 'demo') newState.isDemo = false;
      if (mode === 'upload' || mode === 'drop') newState.useImport = true;
      if (mode === 'manual') newState.useManual = true;

      AmplitudeService.setUserParams(newState);
      storeUserState(newState);
      return newState;
    }
    case USER_INCREASE_USAGE: {
      const newState = assign({}, state);
      if (payload.toursPlanned) newState.usage.toursPlanned += payload.toursPlanned;
      if (payload.ordersDispatched) {
        newState.usage.ordersDispatched += payload.ordersDispatched;
        newState.usage.toursDispatched += payload.toursDispatched || 1;
      }
      AmplitudeService.setUserParams(newState);
      storeUserState(newState);
      return newState;
    }
    case SET_UPLOADED_FILE: {
      if (!payload || !payload.file) return state;

      const newState = assign({}, state);
      newState.lastImport = payload;
      newState.lastImport.delimiter = guessDelimiter(payload.file);
      return newState;
    }
    case DELETE_UPLOADED_FILE: {
      const newState = assign({}, state);
      newState.lastImport = null;
      return newState;
    }
    default: {
      return state;
    }
  }
};
