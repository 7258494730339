import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { size } from 'lodash';
import withTranslation from 'hoc/withTranslation';
import {
  deleteUploadedFile,
  setError,
  setSolutionJson,
  setSolutionParam,
  setTerritoryParameter,
  setUserParam,
} from 'actions';
import { useDispatch } from 'react-redux';
import Button from '../../Form/Button';
import { colors } from '../../../global/variables';
import { hasOngoingAsyncRequest } from '../../../utils/SolutionHelpers';
import { isSolutionMonoTour } from '../../../utils/MemoryHelpers';

const { white } = colors;

const StyledContainer = styled.div(({ step }) => ({
  height: '4.5rem',
  padding: '0.9rem',
  boxSizing: 'border-box',
  borderTop: `solid 1px rgba(0,0,0,0.05)`,
  backgroundColor: white,
  position: 'absolute',
  bottom: '0',
  left: '0',
  right: '0',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: step === 4 ? '1fr 1fr 1fr' : '1fr 1fr',
  textRendering: 'geometricPrecision',
  zIndex: 900,
  div: {
    width: '95%',
    justifySelf: 'end',
  },
  'div:first-of-type': {
    justifySelf: 'start',
  },
}));

const StyledCourtain = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  padding: '1rem',
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, .8)',
  zIndex: 1000,
});

const WizardFooter = ({
  step,
  onClick,
  user,
  onClickTour,
  isSubmitting,
  orders,
  tourData,
  tourPlanner,
  disabled,
  isJsonViewerMode,
  hasNoApiKey,
  isDemoMode,
  backToStep,
  currentSelectedTour,
  solution,
  translations: {
    tourPlanner: { submitTrans },
    tourSolution: { backTrans },
    wizard: {
      next,
      toursDetails: { nextTour, previousTour, emptyOrder },
    },
  },
}) => {
  const dispatch = useDispatch();

  const hasDepot = !!(tourPlanner && tourPlanner.location && tourPlanner.location.value);
  const showTourBtns = tourData && size(tourData.tours) > 1;
  const minOrders = hasDepot ? 1 : 2;
  const hasOrders = orders && orders.length >= minOrders;
  const disabledBack = hasOngoingAsyncRequest(solution.requests);
  const handleDeleteUploadedFile = useCallback(() => dispatch(deleteUploadedFile()), [dispatch]);
  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const handleSetSolutionParameter = useCallback(
    (data) => dispatch(setSolutionParam(data)),
    [dispatch],
  );
  const handleSetTerritoryParameter = useCallback(
    (parameter) => dispatch(setTerritoryParameter(parameter)),
    [dispatch],
  );
  const handleSetError = useCallback((data) => dispatch(setError(data)), [dispatch]);
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);

  const onPlanTourClick = useCallback(() => {
    const invalidPosition = orders.some(
      (order) => order.Position === 'order' && !order.PositionValue,
    );

    if (invalidPosition) {
      handleSetError(emptyOrder);
      return;
    }
    let timesPlanned = (solution && solution.requests[solution.show].timesPlanned) || 0;
    handleSetSolutionParameter({
      param: { timesPlanned: (timesPlanned += 1) },
      index: solution.show,
    });

    onClick(step + 1);
    handleSetSolutionJson({ json: true });
    handleSetUserParam({ jsonPanelOpen: true });
    handleSetTerritoryParameter({ csvPlan: true });

    if (tourPlanner.ordersMode !== 'json_import') {
      if (user.lastAddedOrdersMode === 'import' && user.lastImport?.filename) {
        if (!solution.requests[solution.show].name)
          handleSetSolutionParameter({ param: { name: user.lastImport?.filename } });
        else handleSetSolutionParameter({ param: { name: user.lastAddedOrdersMode } });
      } else {
        handleDeleteUploadedFile();
        handleSetSolutionParameter({ param: { name: user.lastAddedOrdersMode } });
      }
    }
  }, [
    handleSetSolutionJson,
    tourPlanner,
    handleSetSolutionParameter,
    onClick,
    step,
    user,
    handleDeleteUploadedFile,
    solution,
    handleSetUserParam,
  ]);

  return (
    <StyledContainer step={step}>
      {step === 1 && (
        <>
          <div />
          <Button
            id="button-fleet-next"
            text={next}
            onClick={() => onClick(step + 1)}
            disabled={disabled}
          />
        </>
      )}
      {step === 2 && (
        <>
          <Button
            id="button-orders-back"
            inverted
            text={backTrans}
            onClick={() => onClick(step - 1)}
          />
          <Button
            id="button-orders-next"
            text={submitTrans}
            disabled={
              isSubmitting ||
              !hasOrders ||
              (hasNoApiKey && !isDemoMode && !hasOrders) ||
              isJsonViewerMode
            }
            onClick={onPlanTourClick}
          />
        </>
      )}
      {step === 3 && (
        <>
          <Button
            id="button-solution-back"
            inverted
            text={backTrans}
            onClick={() =>
              onClick(
                step - 1,
                false,
                tourData.tours.length === 0 && tourData?.unassigned?.length > 0,
              )
            }
            disabled={disabledBack}
          />
          <div />
        </>
      )}
      {step === 4 && (
        <>
          <Button
            id="button-tour-details-back"
            inverted
            text={backTrans}
            onClick={() => onClick(step - 1)}
            disabled={isSolutionMonoTour(solution) && disabledBack}
          />
          {showTourBtns && (
            <>
              <Button
                id="button-tour-details-back"
                text={previousTour}
                disabled={currentSelectedTour === 0}
                onClick={() => onClickTour({ previous: true })}
              />
              <Button
                id="button-tours-next"
                disabled={tourData.tours.length - 1 === currentSelectedTour}
                text={nextTour}
                onClick={() => onClickTour({ previous: false })}
              />
            </>
          )}
        </>
      )}
      {step === 5 && (
        <Button
          id="button-tour-details-back"
          inverted
          text={backTrans}
          onClick={() => onClick(backToStep, true)}
        />
      )}
      {disabled && <StyledCourtain />}
    </StyledContainer>
  );
};

export default withTranslation(WizardFooter);
