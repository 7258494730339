export function isCollinear(p1, p2, p3) {
  return (p2[1] - p1[1]) * (p3[0] - p1[0]) === (p3[1] - p1[1]) * (p2[0] - p1[0]);
}

export function getOverlap(p1, p2, p3, p4) {
  if (p1[0] > p2[0] || (p1[0] === p2[0] && p1[1] > p2[1])) [p1, p2] = [p2, p1];
  if (p3[0] > p4[0] || (p3[0] === p4[0] && p3[1] > p4[1])) [p3, p4] = [p4, p3];

  const startX = Math.max(p1[0], p3[0]);
  const endX = Math.min(p2[0], p4[0]);

  const startY = Math.max(p1[1], p3[1]);
  const endY = Math.min(p2[1], p4[1]);

  if (startX <= endX && startY <= endY) {
    return { start: [startX, startY], end: [endX, endY] };
  }

  return null;
}

export function findIntersection(p1, p2, p3, p4) {
  const det = (p2[0] - p1[0]) * (p4[1] - p3[1]) - (p2[1] - p1[1]) * (p4[0] - p3[0]);
  if (det === 0) return null;

  const t = ((p3[0] - p1[0]) * (p4[1] - p3[1]) - (p3[1] - p1[1]) * (p4[0] - p3[0])) / det;
  const u = ((p3[0] - p1[0]) * (p2[1] - p1[1]) - (p3[1] - p1[1]) * (p2[0] - p1[0])) / det;

  if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
    const x = p1[0] + t * (p2[0] - p1[0]);
    const y = p1[1] + t * (p2[1] - p1[1]);
    return [x, y];
  }

  return null;
}

export function findPolylineIntersections(polyline1, polyline2) {
  const intersections = [];
  for (let i = 0; i < polyline1.length - 1; i++) {
    for (let j = 0; j < polyline2.length - 1; j++) {
      const [p1, p2] = [polyline1[i], polyline1[i + 1]];
      const [p3, p4] = [polyline2[j], polyline2[j + 1]];

      if (
        (p1[0] === p4[0] && p1[1] === p4[1] && p2[0] === p3[0] && p2[1] === p3[1]) ||
        (p1[0] === p3[0] && p1[1] === p3[1] && p2[0] === p4[0] && p2[1] === p4[1])
      ) {
        return intersections;
      }

      const intersection = findIntersection(p1, p2, p3, p4);
      if (intersection) intersections.push(intersection);
    }
  }
  return intersections;
}

export function findPolylineOverlaps(polyline1, polyline2) {
  const overlaps = [];
  for (let i = 0; i < polyline1.length - 1; i++) {
    for (let j = 0; j < polyline2.length - 1; j++) {
      const [p1, p2] = [polyline1[i], polyline1[i + 1]];
      const [p3, p4] = [polyline2[j], polyline2[j + 1]];

      if (isCollinear(p1, p2, p3) && isCollinear(p1, p2, p4)) {
        const overlap = getOverlap(p1, p2, p3, p4);
        if (overlap) overlaps.push([overlap.start, overlap.end]);
      }
    }
  }
  return overlaps;
}

export function calculateColorIntensity(count) {
  const intensity = Math.max(0, 255 - count * 50); // Higher count = darker red
  return `rgb(${intensity}, 0, 0)`;
}

export function calculateOverlapColorIntensity(count) {
  const intensity = Math.max(0, 255 - count * 50); // Higher count = darker blue
  return `rgb(0, 0, ${intensity})`;
}

export function findIntersectingAndOverlappingPolylines(polylines) {
  const results = {
    intersections: [],
    overlaps: [],
  };

  const intersectionCount = new Map(); // Track occurrences of each intersection
  const overlapCount = new Map(); // Track occurrences of each overlap

  for (let i = 0; i < polylines.length; i++) {
    for (let j = i + 1; j < polylines.length; j++) {
      const polyline1 = polylines[i];
      const polyline2 = polylines[j];

      const intersections = findPolylineIntersections(polyline1, polyline2);
      const overlaps = findPolylineOverlaps(polyline1, polyline2);

      // Track intersection occurrences
      intersections.forEach((intersection) => {
        const key = `${intersection[0]},${intersection[1]}`;
        intersectionCount.set(key, (intersectionCount.get(key) || 0) + 1);
      });

      // Track overlap occurrences
      overlaps.forEach(([start, end]) => {
        const key = `${start[0]},${start[1]}-${end[0]},${end[1]}`;
        overlapCount.set(key, (overlapCount.get(key) || 0) + 1);
      });

      if (intersections.length > 0) {
        intersections.forEach((intersection) => {
          const count = intersectionCount.get(`${intersection[0]},${intersection[1]}`);
          results.intersections.push({
            point: intersection,
            color: calculateColorIntensity(count),
            count,
          });
        });
      }

      if (overlaps.length > 0) {
        overlaps.forEach(([start, end]) => {
          const count = overlapCount.get(`${start[0]},${start[1]}-${end[0]},${end[1]}`);
          results.overlaps.push({
            segment: [start, end],
            color: calculateOverlapColorIntensity(count),
            count,
          });
        });
      }
    }
  }

  return results;
}
