import { concat, filter, flatten, isEmpty, isEqual, size, get, first, uniq, compact } from 'lodash';
import {
  getHouseKeys,
  getJobTimeWindows,
  getSequenceNumbersOfJobs,
  getStopLocation,
  isRecharge,
} from './SolutionHelpers';
import {
  getOrdersByStop,
  getLabelsFromOrders,
  getOrdersByLocation,
  getOrdersByInternalID,
  getAddressFromOrders,
  getOrdersAddress,
  hasHighPriorityOrders,
  getOrdersByActivity,
  getJobPosition,
} from './OrdersHelpers';
import { getBoundingCoordinates } from './MapHelpers';
import { getSafeValue } from './security';
import { filterAreasByType, isOrderInTerritory } from './territories/TerritoriesHelpers';
import { tourColors } from '../global/variables';
import { AREA_TYPES } from './territories/TerritoriesFromJson';
import { ORDER_ACTIVITIES } from './csv/config';

const mapFeatures = (coordinates = []) =>
  coordinates.map(({ coordinate, properties = {} }) => ({
    type: 'Feature',
    geometry: { coordinates: coordinate, type: 'Point' },
    properties,
  }));

export const calculateBBox = (coordinates, distance = 100) => {
  if (coordinates.length === 1) return getBoundingCoordinates(coordinates[0], distance);
  return [
    Math.min(...coordinates.map((stop) => stop[0])),
    Math.min(...coordinates.map((stop) => stop[1])),
    Math.max(...coordinates.map((stop) => stop[0])),
    Math.max(...coordinates.map((stop) => stop[1])),
  ];
};

const getFeaturePerPlace = (job, place, jobNumbers, orders, coordinates, unassigned, types) => {
  const stopOrders = getOrdersByInternalID(getOrdersByLocation(orders, place.location), job.id);
  const address = !isEmpty(stopOrders) ? stopOrders[0].Address : null;
  const labels = getLabelsFromOrders(stopOrders);
  return {
    type: 'Feature',
    geometry: { coordinates, type: 'Point' },
    properties: {
      jobId: job.id,
      jobNumber: getSafeValue(jobNumbers, job.id),
      reason: unassigned.reasons,
      labels,
      address,
      types,
    },
  };
};

export const convertToGeoJSON = (
  solution,
  stopsNoLocation,
  plannedJobs = [],
  orders = [],
  tourPlanner,
  areas,
  depot,
) => {
  if (!solution) {
    return undefined;
  }

  const geoJSON = {};
  const features = [];
  const allCoordinates = [];
  const jobNumbers = getSequenceNumbersOfJobs(plannedJobs);
  const tw = getJobTimeWindows(plannedJobs);
  const hk = getHouseKeys(plannedJobs);

  (solution.tours || []).forEach((tour, tourIndex) => {
    if (!tour.stops) return;
    const coordinates = [];
    const jobMarkerColor = tourColors[tourIndex % tourColors.length];
    tour.stops.forEach((stop, index) => {
      const stopLocation = getStopLocation(stop, stopsNoLocation, index, tourIndex);
      coordinates.push([stopLocation.lng, stopLocation.lat]);
      const stopOrders = getOrdersByStop(orders, stop);
      let address = getOrdersAddress(stopOrders);
      const labels = getLabelsFromOrders(stopOrders);
      const position = getJobPosition(stopOrders);
      const highPriority = hasHighPriorityOrders(stopOrders);
      if (index === 0) {
        address =
          tourPlanner &&
          tourPlanner.location &&
          tourPlanner.location.value &&
          tourPlanner.location.value.lat &&
          tourPlanner.location.label;
      }
      if (
        index === size(tour.stops) - 1 &&
        tourPlanner &&
        tourPlanner.returnLocation !== undefined &&
        tourPlanner.returnLocation.value !== null
      ) {
        address = tourPlanner.returnLocation.label;
      }

      const territories = filterAreasByType(areas, AREA_TYPES.TERRITORY);
      const groups = filterAreasByType(areas, AREA_TYPES.GROUP);

      features.push({
        type: 'Feature',
        geometry: { coordinates: [stopLocation.lng, stopLocation.lat], type: 'Point' },
        properties: {
          // filtering out breaks
          jobNumbers: stop.activities
            .map((a) => getSafeValue(jobNumbers, a.jobId))
            .filter((v) => v !== undefined),
          tw: stop.activities.map((a) => getSafeValue(tw, a.jobId)).filter((v) => v !== undefined),
          routeId: tourIndex,
          arr_time: stop.time.arrival,
          dep_time: stop.time.departure,
          jobOrder: index,
          skills: uniq(compact(stopOrders.map((order) => order.Skills))),
          types: stop.activities.map((a) => a.type),
          jobIds: stop.activities.map((a) => a.jobId),
          jobTags: stop.activities.map((a) => a.jobTag).filter((val) => val),
          vehicle: { type: tour.vehicleId },
          territory: isOrderInTerritory(stopOrders, territories),
          group: isOrderInTerritory(stopOrders, groups),
          labels,
          address,
          highPriority,
          jobMarkerColor,
          position,
          load: stop.load,
          categories: uniq(compact(stopOrders.map((order) => order.Category))),
          priority: uniq(compact(stopOrders.map((order) => order.Priority))),
        },
      });

      stop.activities.forEach((activity) => {
        const activityOrder = getOrdersByActivity(stopOrders, activity);
        const findOrder = activityOrder?.find((order) => order?.InternalID === activity?.jobId);
        const label = getLabelsFromOrders(activityOrder);
        const activityLocation =
          !activity.location ||
          activity.type === 'drivingRestTime' ||
          activity.type === 'workingRestTime'
            ? stopLocation
            : activity.location;
        const arr_time = activity.time ? activity.time.start : stop.time.arrival;
        const dep_time = activity.time ? activity.time.end : stop.time.departure;
        const skills = findOrder?.Skills || null;
        const priority = findOrder?.Priority ? [findOrder.Priority] : [];
        const pos = findOrder?.Position ? [findOrder.Position] : [];
        const load = findOrder?.Demand || null;

        features.push({
          type: 'Feature',
          geometry: {
            coordinates: [activityLocation.lng, activityLocation.lat],
            type: 'Point',
          },
          properties: {
            stopLocation,
            routeId: tourIndex,
            arr_time,
            dep_time,
            types: [activity.type],
            jobIds: [activity.jobId],
            jobTags: [activity.jobTag],
            distance: stop.activities.some((a) => isRecharge(a.type)) && {
              distance: stop.distance,
              tourIndex,
            },
            tourIndex,
            tw: stop.activities
              .map((a) => getSafeValue(tw, a.jobId))
              .filter((v) => v !== undefined),
            houseKeyId: stop.activities
              .map((a) => getSafeValue(hk, a.jobId))
              .filter((v) => v !== undefined),
            vehicle: { type: tour.vehicleId },
            stopIndex: index,
            territory: isOrderInTerritory([activityOrder], territories),
            stopTerritory: isOrderInTerritory(stopOrders, territories),
            group: isOrderInTerritory(activityOrder, groups),
            jobMarkerColor,
            labels: label,
            skills,
            priority,
            position: pos,
            load,
            categories: uniq(compact(stopOrders.map((order) => order.Category))),
          },
        });
      });
    });

    allCoordinates.push(...coordinates);

    const line = {
      geometry: {
        coordinates,
        type: 'LineString',
      },
      type: 'Feature',
      properties: {
        routeId: tourIndex,
        cost: tour.statistic.cost,
        distance: tour.statistic.distance,
        duration: tour.statistic.duration,
        times: tour.statistic.times,
        vehicle: { type: tour.typeId, id: tour.vehicleId },
      },
    };
    line.bbox = calculateBBox(coordinates);
    features.push(line);
  });

  const unassignedCoordinates = [];

  if (depot) {
    unassignedCoordinates.push([depot.lng, depot.lat]);
    allCoordinates.push([depot.lng, depot.lat]);

    features.push({
      type: 'Feature',
      geometry: {
        coordinates: [depot.lng, depot.lat],
        type: 'Point',
      },
      properties: {
        isUnassigned: true,
        isDepot: true,
      },
    });
  }

  (solution.unassigned || []).forEach((unassigned) => {
    const job = plannedJobs.find((item) => item.id === unassigned.jobId);
    if (!job) return;

    let coordinates = [];

    if (job.tasks.deliveries && job.tasks.deliveries[0]) {
      job.tasks.deliveries.forEach((delivery) => {
        const places = delivery.places;
        places.forEach((place) => {
          coordinates = [place.location.lng, place.location.lat];
          const feature = getFeaturePerPlace(
            job,
            place,
            jobNumbers,
            orders,
            coordinates,
            unassigned,
            ['delivery'],
          );
          features.push(feature);
          allCoordinates.push(coordinates);
          unassignedCoordinates.push(coordinates);
        });
      });
    }
    if (job.tasks.pickups && job.tasks.pickups[0]) {
      job.tasks.pickups.forEach((pickup) => {
        const places = pickup.places;
        places.forEach((place) => {
          coordinates = [place.location.lng, place.location.lat];
          const feature = getFeaturePerPlace(
            job,
            place,
            jobNumbers,
            orders,
            coordinates,
            unassigned,
            ['pickup'],
          );
          features.push(feature);
          allCoordinates.push(coordinates);
        });
      });
    }
  });

  const valid = allCoordinates.filter((c) => c[0] && c[1]);
  if (valid.length === 0) return null;

  const bbox = calculateBBox(allCoordinates);
  const validUnassigned = unassignedCoordinates.filter((c) => c[0] && c[1]);
  const unassignedBbox = size(validUnassigned) > 0 ? calculateBBox(validUnassigned) : bbox;

  geoJSON.geo = { features, bbox, unassignedBbox };

  return geoJSON;
};

export const convertProblemToGeoJSON = (
  { plan, fleet } = {},
  depot,
  returnLocation,
  orders = [],
  depotAddress,
  returnLocationAddress,
  editedOrder,
  territories,
) => {
  if (!plan || !fleet) return null;

  const { jobs, groups } = plan;
  const { types } = fleet;

  if (!size(types)) return null;

  const locationsDelivery = concat(
    flatten(
      jobs.map(({ id, tasks }) =>
        flatten(
          tasks?.deliveries
            ?.filter((task) => !isEmpty(task))
            .map((task) =>
              task.places
                .filter(({ location }) => location?.lat && location?.lng)
                .map(({ location, times }) => ({
                  coordinate: [location.lng, location.lat],
                  properties: {
                    id,
                    address: getAddressFromOrders(getOrdersByInternalID(orders, id)),
                    labels: getLabelsFromOrders(getOrdersByInternalID(orders, id)),
                    isPickup: false,
                    types: ['delivery'],
                    tw: times || [],
                    highPriority: hasHighPriorityOrders(getOrdersByInternalID(orders, id)),
                    territory: isOrderInTerritory(orders, territories),
                  },
                })),
            ),
        ),
      ),
    ),
    [],
  );

  const locationsPickup = concat(
    flatten(
      jobs.map(({ id, tasks }) =>
        flatten(
          tasks?.pickups
            ?.filter((task) => !isEmpty(task))
            .map((task) =>
              task.places
                .filter(({ location }) => location?.lat && location?.lng)
                .map(({ location, times }) => ({
                  coordinate: [location.lng, location.lat],
                  properties: {
                    id,
                    address: getAddressFromOrders(getOrdersByInternalID(orders, id)),
                    labels: getLabelsFromOrders(getOrdersByInternalID(orders, id)),
                    isPickup: true,
                    types: ['pickup'],
                    tw: times || [],
                    highPriority: hasHighPriorityOrders(getOrdersByInternalID(orders, id)),
                    territory: isOrderInTerritory(orders, territories),
                  },
                })),
            ),
        ),
      ),
    ),
    [],
  );
  const locations = concat(locationsDelivery, locationsPickup);

  if (groups)
    locations.push(
      ...groups.flatMap(({ pudos, id: groupId }) =>
        pudos.flatMap(({ id, places }) =>
          places.map(({ location }) => ({
            coordinate: [location.lng, location.lat],
            properties: {
              id,
              groupId,
              address: getAddressFromOrders(getOrdersByInternalID(orders, id)),
              labels: getLabelsFromOrders(getOrdersByInternalID(orders, id)),
              types: [ORDER_ACTIVITIES.PUDO],
              isPudo: true,
              highPriority: hasHighPriorityOrders(getOrdersByInternalID(orders, id)),
              territory: isOrderInTerritory(orders, territories),
            },
          })),
        ),
      ),
    );

  if (editedOrder && editedOrder.Latitude && editedOrder.Longitude) {
    locations.push({
      coordinate: [editedOrder.Longitude, editedOrder.Latitude],
      properties: {
        isEditing: true,
        types: [editedOrder.Activity],
        address: editedOrder.Address,
        labels: getLabelsFromOrders([editedOrder]),
      },
    });
  }

  if (locations.length === 0 && !depot && !returnLocation) return null;

  let depots = [];
  if (depot || (types && !isEmpty(types[0].shifts[0].start.location))) {
    depots = (
      depot && depot.lat && depot.lng
        ? [[depot.lng, depot.lat]]
        : flatten(
            types.map(({ shifts: [{ start: shiftStart }] }) => {
              const firstLocLat = first(locations).coordinate[1];
              const firstLocLng = first(locations).coordinate[0];

              const startLat = shiftStart?.location?.lat ?? firstLocLat;
              const startLng = shiftStart?.location?.lng ?? firstLocLng;

              const endLoc = get(types, 'shifts.end.location');
              const endLat = endLoc ? endLoc.lat : null;
              const endLng = endLoc ? endLoc.lng : null;
              if (!endLat || !endLng) return [[startLng, startLat]];
              return isEqual(startLat, endLat) && isEqual(startLng, endLng)
                ? [[startLng, startLat]]
                : [
                    [startLng, startLat],
                    [endLng, endLat],
                  ];
            }),
          )
    ).map((coordinate) => ({
      coordinate,
      properties: {
        isDepot: true,
        address: depotAddress,
      },
    }));
  }

  let returnLocationFeature = [];
  if (!isEmpty(returnLocation) && returnLocationAddress !== depotAddress) {
    returnLocationFeature = [
      {
        coordinate: [returnLocation.lng, returnLocation.lat],
        properties: {
          isReturnLocation: true,
          address: returnLocationAddress,
        },
      },
    ];
  }

  return {
    features: concat(
      mapFeatures(locations),
      mapFeatures(depots),
      mapFeatures(returnLocationFeature),
    ),
    bbox: calculateBBox(
      concat(
        locations.map(({ coordinate }) => coordinate),
        depots.map(({ coordinate }) => coordinate),
        returnLocationFeature.map(({ coordinate }) => coordinate),
      ),
    ),
    type: 'FeatureCollection',
  };
};

export const hideDepot = ({ geo } = {}) =>
  geo
    ? {
        geo: {
          ...geo,
          features: filter(geo.features, ({ properties: { isDepot } }) => !isDepot),
        },
      }
    : {};
