import { isEmpty, get, size, first, keys, uniq, compact } from 'lodash';
import moment from 'moment';
import { sortOrdersByComplexAddress } from './csv/clusters';
import { ORDER_PRIORITY } from './csv/config';
import { getSafeValue, setSafeValue } from './security';
import { isBreakOrReload, isRecharge } from './SolutionHelpers';

export function isSameLocation(a, b, toDigit) {
  return a.toFixed(toDigit) === b.toFixed(toDigit);
}

export function compareLocationLatAndLng(a, b, pwr) {
  if (!a || !b) return null;
  return a.lng.toFixed(pwr) === b.lng.toFixed(pwr) && a.lat.toFixed(pwr) === b.lat.toFixed(pwr);
}

export function getOrdersByLocation(orders, location) {
  if (!orders || !location || !location.lat || !location.lng) return null;
  return orders.filter((o) => o.Latitude === location.lat && o.Longitude === location.lng);
}

export function getOrdersByInternalID(orders, ID) {
  return orders && ID ? orders.filter((o) => o.InternalID === ID) : null;
}

export function getOrdersByActivity(orders, activity) {
  if (!orders || isEmpty(orders) || !activity) return null;

  const actType = activity.type;
  if (isBreakOrReload(actType) || isRecharge(actType))
    return [{ Activity: actType, ID: activity.jobId, Address: activity.jobTag || activity.type }];
  return sortOrdersByComplexAddress(
    orders.filter(
      (o) =>
        o.InternalID === activity.jobId &&
        o.Activity === actType &&
        (activity.jobTag === undefined || o.Address === activity.jobTag),
    ),
  );
}

function sortByTime(inputArray) {
  const groupedOrders = inputArray.reduce((groups, item) => {
    const { allOrders, time } = item;
    if (allOrders && allOrders.length > 0) {
      const hasBreakOrReload = allOrders.some((order) => isBreakOrReload(order.Activity));

      let key;
      if (hasBreakOrReload) {
        key = JSON.stringify(item);
      } else {
        key = JSON.stringify(allOrders);
      }

      if (!groups[key]) {
        groups[key] = { allOrders, times: [] };
      }
      groups[key].times.push(time);
    }
    return groups;
  }, {});

  return Object.values(groupedOrders)
    .flatMap((group) =>
      group.allOrders.map((order, index) => ({
        ...order,
        times: group.times[index],
      })),
    )
    .sort((a, b) => moment(a.times).diff(moment(b.times), 'minutes'));
}

export function getOrdersByStop(orders, stop) {
  if (!orders || isEmpty(orders) || !stop) return null;

  const ordersInStop = stop.activities.map((act) => {
    const actType = act.type;
    const time = act.time && act.time.start;

    if (isBreakOrReload(actType) || isRecharge(actType)) {
      return {
        time,
        allOrders: [{ Activity: actType, ID: act.jobId, Address: act.jobTag || act.type }],
      };
    }

    const location = act.location || stop.location;
    const allOrders = orders.filter(
      (o) =>
        o.InternalID === act.jobId &&
        o.Activity === actType &&
        isSameLocation(o.Latitude, location.lat, 6) &&
        isSameLocation(o.Longitude, location.lng, 6) &&
        (act.jobTag === undefined || o.Address === act.jobTag),
    );

    return { time, allOrders };
  });

  return sortByTime(ordersInStop);
}

export function getAddressFromOrders(orders) {
  return !isEmpty(orders) && orders[0].Address;
}

export function getLabelsFromOrders(orders, extended, translations) {
  if (!orders) return null;

  const phoneTrans = get(translations, 'phoneTrans');
  const details = orders.map((o) => {
    const values = [];
    // For orders generated from uploaded JSON files, the name contains the job ID
    let basicInfo = [o.ID, o.Name, o['c/o'], o.Demand, o.Activity];
    if (o.Name && o.ID && o.Name.includes(o.ID)) basicInfo = [o.Name, o.Demand];
    values.push(basicInfo.filter((e) => !!e).join(', '));
    if (extended) {
      if (o.Phone) values.push(`${phoneTrans}: ${o.Phone}`);
      values.push(o.Email);
      values.push(o.Notes);
    }
    const valid = values.filter((v) => !!v);
    return `\n${valid.join('\n')}`;
  });
  return details.filter((n) => !!n).concat('\n');
}

export function getTourOrders(tour, request) {
  return tour.stops.reduce((acc, stop) => acc.concat(getOrdersByStop(request.orders, stop)), []);
}

export function getAllTourOrders(tours, request) {
  return tours.map((t) => getTourOrders(t, request));
}

export function getTourDemand(tour, request) {
  const orders = getTourOrders(tour, request);
  return orders.reduce((acc, order) => acc + (parseInt(order.Demand, 10) || 1), 0);
}

export const getAllAddressesInOrders = (orders) => {
  const mappedAddresses = (orders || []).reduce((t, order) => {
    if (!order.Address) return t;
    if (!getSafeValue(t, order.Address)) setSafeValue(t, order.Address, 0);
    setSafeValue(t, order.Address, getSafeValue(t, order.Address) + 1);
    return t;
  }, {});
  return keys(mappedAddresses);
};

export const getOrdersAddress = (orders) => {
  if (!orders || isEmpty(orders)) return null;
  if (size(orders) === 1) return orders[0].Address;

  const firstOrder = first(orders);
  // For orders generated from uploaded JSON files, Address and ID are the same
  if (firstOrder.ID === firstOrder.Address) return '';

  const addresses = getAllAddressesInOrders(orders);
  if (size(addresses) === 1 && firstOrder.Address) return firstOrder.Address.split(',')[0];

  return addresses.join(', ');
};

export const getJobPosition = (orders) => {
  if (!orders || isEmpty(orders)) return [];

  return uniq(compact(orders.map((order) => order.Position)));
};

export const getHighPriorityOrders = (orders) =>
  orders.filter((o) => o.Priority === ORDER_PRIORITY.HIGH);

export const hasHighPriorityOrders = (orders) => getHighPriorityOrders(orders).length > 0;

export const isStopArrival = (stop) =>
  stop.activities.filter((act) => act.jobId === 'arrival').length > 0;

export const sortOrdersBy = (orders, propName) => {
  if (isEmpty(orders) || isEmpty(propName)) return orders;

  return orders.sort((a, b) => {
    const aValue = getSafeValue(a, propName);
    const bValue = getSafeValue(b, propName);
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  });
};

export function filterOrdersByBreaksAndReloads(orders) {
  if (!orders) return null;
  return orders.filter((o) => !isBreakOrReload(o.Activity));
}
