import React, { useEffect, useState, useCallback } from 'react';
import withTranslation from 'hoc/withTranslation';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { setLoader, setError, setSolutionParam, deleteSolution, setSolutionJson } from 'actions';
import { colors, fontSize } from 'global/variables';
import { css } from '@emotion/react';
import { RequestStatus } from 'components/FloatingPanels/Tours/Tour';
import Spinner from '../../Presentation/Spinner';
import { StyledIcon, StyledFullPanelContainer } from './WizardStyled';
import Button from '../../Form/Button';
import { getAsset } from '../../../utils/helpers';
import warningIcon from '../../../global/img/warning-icon.svg';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { getSafeValue } from '../../../utils/security';

const { aqua, black, primaryTextColor, secondaryTextColor } = colors;
const { normal, medium } = fontSize;

export const WarningIconStyle = css`
  background-image: url(${getAsset(warningIcon)});
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  background-size: 1.4rem;
  background-position: center !important;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  animation: EntryAnimationNotificationIcon 0.3s 1;
`;

const StyledNotificationText = styled.div(({ isLoader }) => ({
  height: isLoader ? 'inherit' : 'fill-available',
  gap: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
  width: '60%',
  padding: '20%',
  lineHeight: '1.2rem',
  borderRadius: '2px',
  p: {
    marginBottom: '2rem',
    marginTop: 0,
    fontSize: medium,
    color: primaryTextColor,
    fontWeight: 300,
    lineHeight: '1.2rem',
  },
  h1: {
    fontSize: normal,
    color: secondaryTextColor,
  },
}));

const StyledNotificationSpinner = styled.div({
  margin: '2rem auto',
  zIndex: 4000,
});

const StyledLink = styled.a({
  textDecoration: 'none',
  fontSize: medium,
  color: black,
  ':active': {
    color: black,
  },
});

const StyledErrorMessageContainer = styled.div({
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
});

const StyledButtonContainer = styled.div({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'column',
  width: '100%',
});

const Notifications = ({ user, translations }) => {
  const dispatch = useDispatch();
  const error = useSelector(({ error: stateError }) => stateError);
  const loader = useSelector(({ loader: stateLoader }) => stateLoader);
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const handleSetLoader = useCallback((data) => dispatch(setLoader(data)), [dispatch]);
  const handleSetError = useCallback((data) => dispatch(setError(data)), [dispatch]);
  const handleDeleteSolution = useCallback((idx) => dispatch(deleteSolution(idx)), [dispatch]);
  const handleSetSolutionParameter = useCallback(
    (data) => dispatch(setSolutionParam(data)),
    [dispatch],
  );
  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const [message, setMessage] = useState(null);
  const {
    notification: { errorHeader, planningTourHeader, buttonTryAgain },
    contact: { linkTitle },
  } = translations;

  useEffect(() => {
    if (!error && (!loader || !loader.isLoading)) {
      setMessage();
      return;
    }

    if (error) {
      const { errorMessage, errorCode } = error;
      const text = errorCode ? getSafeValue(translations.error, errorCode) : errorMessage;
      setMessage(text);
      AmplitudeService.log(AMPLITUDE_EVENTS.ERROR, error);
      return;
    }

    if (loader.isLoading) setMessage(getSafeValue(translations.loader, loader.message));
  }, [error, loader, user, translations, setMessage]);

  useEffect(() => {
    if (!loader.isLoading && !error) setMessage();
  }, [user.language, loader.isLoading, setMessage, error]);

  const onClick = useCallback(
    (msg) => {
      const isExperimentalError = msg?.includes('Experimental feature');
      if (isExperimentalError) handleSetSolutionJson({ jsonUserChange: true });
      const isObjectiveError = msg?.includes('objective');
      if (msg === RequestStatus.FAILURE)
        handleDeleteSolution({ index: [solution.show], current: solution.show });
      if (isObjectiveError) handleSetSolutionJson({ json: false });
      handleSetError(null);
      handleSetLoader({ isLoading: false });
      handleSetSolutionParameter({ iterationParam: true, param: { isProcessing: false } });
      handleSetSolutionParameter({ param: { error: null }, index: solution.show });
    },
    [
      handleSetLoader,
      handleSetError,
      handleSetSolutionParameter,
      handleDeleteSolution,
      handleSetSolutionJson,
      solution,
    ],
  );

  if (!message) return <></>;

  return (
    <StyledFullPanelContainer isError={error}>
      <StyledNotificationText id="label-error-message" isLoader={loader.isLoading}>
        {error && <StyledIcon icon={WarningIconStyle} />}
        {loader && loader.isLoading && (
          <StyledNotificationSpinner>
            <Spinner color={aqua} />
          </StyledNotificationSpinner>
        )}

        <h1>{error ? errorHeader : planningTourHeader}</h1>
        <StyledErrorMessageContainer>
          <p id="label-error-message">{message}</p>
        </StyledErrorMessageContainer>

        <StyledButtonContainer>
          {error && !error.isFatal && (
            <Button
              id="button-error-try-again"
              text={buttonTryAgain}
              onClick={() => onClick(error.errorMessage)}
              inverted
            />
          )}
          {error && (
            <StyledLink href="mailto:TourPlanning@here.com">
              <Button id="button-error-help" text={linkTitle} />
            </StyledLink>
          )}
        </StyledButtonContainer>
      </StyledNotificationText>
    </StyledFullPanelContainer>
  );
};

export default withTranslation(Notifications);
