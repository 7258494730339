import {
  CLEAR_LOCATIONS,
  CLEAR_ORDERS,
  CLEAR_SOLUTION,
  CLEAR_TOUR_PARAMETER,
  CLEAR_VEHICLE_PROFILES,
  DELETE_SOLUTION,
  GET_SOLUTION,
  RETURN_LOCATION_MODE,
  SET_TOUR_PARAMETER,
  USER_SET_PARAM,
} from 'actions';
import config from 'config';
import { assign, cloneDeep } from 'lodash';
import { setStorageValue, STORAGE_IDS } from '../utils/localStorageHelpers';
import { AmplitudeService } from '../utils/amplitude';
import { isMobileDevice } from '../utils/helpers';
import { getStateFromMemory, shouldRememberLastState } from '../utils/MemoryHelpers';

const {
  scenario: {
    times: [{ key: day, value }],
    vehicle,
    vehicleMobile,
  },
  defaults,
} = config;
const isMobile = isMobileDevice();
const vehicleToUse = isMobile ? vehicleMobile : vehicle;
const defaultSolverConfig = {
  terminationCriteria: { enabled: false, values: null },
  objectiveFunctions: { enabled: false, values: null },
};
const defaultReturnLocation = RETURN_LOCATION_MODE.START;
const defaultOrdersMode = isMobile ? 'manual' : 'import';
const defaultProfileName = 'vehicle';
const defaultVehicleId = 'vehicle_1';
const defaultLimits = {
  maxDistance: { enabled: false, value: defaults.limits.maxDistance },
  shiftTime: { enabled: false, value: defaults.limits.shiftTime },
};

export const defaultValue = {
  location: { value: null, label: '' },
  returnLocation: { value: null, label: '' },
  returnLocationMode: defaultReturnLocation,
  time: { key: day, value },
  vehicles: [
    {
      profile: defaultProfileName,
      id: defaultVehicleId,
      amount: vehicleToUse.amount,
      capacity: vehicleToUse.capacity,
      costs: vehicleToUse.costs,
      limits: defaultLimits,
    },
  ],
  ordersMode: defaultOrdersMode,
  editedOrder: null,
  csvConfig: {},
  fileType: null,
  offset: 0,
  solverConfiguration: defaultSolverConfig,
  vehicleProfiles: [{ fleetType: vehicleToUse.profile, name: defaultProfileName }],
  traffic: null,
  userChange: null,
};

const defaultInitialState = {
  value: [defaultValue],
  temp: { vehicles: null, vehicleProfiles: null },
};

const stored = getStateFromMemory(STORAGE_IDS.tourPlanner, defaultInitialState);
const updateStored = stored && stored.value ? stored : defaultInitialState;
const initialState = assign({}, cloneDeep(defaultInitialState), updateStored);
if (initialState.value[initialState.value.length - 1].ordersMode === 'demo')
  initialState.ordersMode = defaultOrdersMode;
AmplitudeService.setFleetConfiguration(initialState.value[initialState.value.length - 1]);

export default (state = cloneDeep(initialState), { payload = {}, type }) => {
  switch (type) {
    case SET_TOUR_PARAMETER: {
      const index = payload.index !== undefined ? payload.index : state.value.length - 1;
      const newState = cloneDeep(state);
      const userChange = payload.userChange;
      if (userChange) {
        newState.temp = assign({}, state.value[index], payload);
      }

      newState.value[index] = assign({}, state.value[index], payload);

      if (payload && payload.location && !payload.location.value)
        delete newState.value[index].location.value;

      setStorageValue(STORAGE_IDS.tourPlanner, newState);
      AmplitudeService.setFleetConfiguration(newState.value[index]);

      return newState;
    }
    case CLEAR_TOUR_PARAMETER: {
      const newState = cloneDeep(state);
      payload.param.forEach(
        (p) => delete newState.value[payload.clearIndex ?? newState.value.length - 1][p],
      );

      setStorageValue(STORAGE_IDS.tourPlanner, newState);
      AmplitudeService.setFleetConfiguration(newState.value[newState.value.length - 1]);

      return newState;
    }
    case CLEAR_ORDERS: {
      const newState = cloneDeep(state);
      newState.value[newState.value.length - 1].fileType = null;
      newState.temp = null;
      setStorageValue(STORAGE_IDS.tourPlanner, newState);
      return newState;
    }
    case CLEAR_LOCATIONS: {
      const newState = cloneDeep(state);
      const idx = newState.value.length - 1;
      newState.value[idx].location = defaultValue.location;
      newState.value[idx].returnLocation = defaultValue.returnLocation;
      newState.value[idx].start = {};
      newState.value[idx].end = {};
      if (newState.value[idx].groups) delete newState.value[idx].groups;
      if (newState.value[idx].configuration) delete newState.value[idx].configuration;

      setStorageValue(STORAGE_IDS.tourPlanner, newState);
      return newState;
    }
    case CLEAR_VEHICLE_PROFILES: {
      const newState = cloneDeep(state);
      const vehicleToUseIndex = newState.value.length === 1 ? 0 : newState.value.length - 2;
      newState.value[payload.index ?? newState.value.length - 1].vehicleProfiles = [
        {
          ...newState.value[vehicleToUseIndex].vehicleProfiles[0],
          name: defaultProfileName,
        },
      ];
      newState.value[payload.index ?? newState.value.length - 1].vehicles = [
        {
          ...newState.value[vehicleToUseIndex].vehicles[0],
          profile: defaultProfileName,
          id: defaultVehicleId,
        },
      ];
      setStorageValue(STORAGE_IDS.tourPlanner, newState);
      AmplitudeService.setFleetConfiguration(
        newState.value[payload.index ?? newState.value.length - 1],
      );
      return newState;
    }
    case GET_SOLUTION: {
      if (payload.error) return state;
      const index = payload.index !== undefined ? payload.index : state.value.length - 1;
      const newState = cloneDeep(state);
      const stateToUse = newState.value[index];
      const vehicleProfiles = [
        {
          ...stateToUse.vehicleProfiles[0],
          name: defaultProfileName,
        },
      ];
      const vehicles = [
        {
          ...stateToUse.vehicles[0],
          profile: defaultProfileName,
          id: defaultVehicleId,
        },
      ];
      const ordersMode =
        payload.keepOrders && stateToUse.ordersMode === 'json_import'
          ? 'manual'
          : stateToUse.ordersMode;

      newState.value[index + 1] = {
        ...stateToUse,
        vehicleProfiles,
        vehicles,
        fileType: null,
        ordersMode,
      };

      setStorageValue(STORAGE_IDS.tourPlanner, newState);
      return newState;
    }
    case CLEAR_SOLUTION: {
      const newState = cloneDeep(state);

      if (newState.value.length > 3 || (!shouldRememberLastState() && newState.value.length > 1)) {
        newState.value.shift();
      }
      setStorageValue(STORAGE_IDS.tourPlanner, newState);
      return newState;
    }
    case DELETE_SOLUTION: {
      const newState = cloneDeep(state);

      if (newState.value.length === 1) {
        newState.value.splice(payload.index, 1);
      }
      newState.value = newState.value.filter((n, idx) => !payload.index.includes(idx));

      if (newState.value.length === 0) newState.value.push(defaultValue);
      if (payload.toEmptyLast)
        newState.value[newState.value.length - 1].ordersMode = defaultOrdersMode;

      setStorageValue(STORAGE_IDS.tourPlanner, newState);
      return newState;
    }
    case USER_SET_PARAM: {
      if (payload.hasStateMemory === false) {
        const newState = cloneDeep(state);
        newState.value = [state.value[state.value.length - 1]];
        setStorageValue(STORAGE_IDS.tourPlanner, newState);
        return newState;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
