import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Selector from 'components/Form/Selector';
import { last } from 'lodash';
import { isIterationSolution } from 'utils/SolutionHelpers';
import Modal from '../../Presentation/Modal';
import { setUserParam } from '../../../actions';
import withTranslation from '../../../hoc/withTranslation';
import {
  StyledContrastPanel,
  StyledContrastPanelColumnsContainer,
  StyledContrastPanelFooter,
  StyledContrastPanelHeader,
} from '../../Wizard/Global/WizardStyledContrastPanel';
import { StyledText } from '../../Wizard/Global/WizardStyled';
import Section from '../../Form/Section';
import { StyledAccessContainer } from '../StyledComponents';
import mapSettings from '../../../global/img/map-settings.svg';
import { PANELS, defaultMapSettings } from '../../../reducers/user';
import Button from '../../Form/Button';
import MultipleActionSetting from './MultipleActionSetting';
import SingleActionSetting from './SingleActionSetting';

const OPTIONS = { General: 'general', Problem: 'problem', Solution: 'solution' };

const MAP_SETTINGS = {
  [OPTIONS.General]: {
    SATELLITE_VIEW: 'satelliteView',
  },
  [OPTIONS.Problem]: {
    SHOW_JOB_CLUSTERS: 'showJobClusters',
  },
  [OPTIONS.Solution]: {
    SHOW_STOPS: 'showStops',
    SHOW_CLUSTERS: 'showStopClusters',
    GRADIENT_ROUTES: 'gradientRoutes',
    SHOW_STRAIGHT_ROUTE_LINES: 'showStraightRouteLines',
    SHOW_TOUR_DIRECTION_ANIMATION: 'showTourDirectionAnimation',
    SHOW_TOUR_CONVEX_HULL: 'showTourConvexHull',
    SHOW_TOUR_CONCAVE_HULL: 'showTourConcaveHull',
    HIDE_ALL_ROUTES: 'hideAllRoutes',
    SHOW_INTERSECTIONS: 'showIntersections',
    SHOW_OVERLAPS: 'showOverlaps',
    HIDE_VISITED: 'hideVisited',
  },
};

const StyledContainer = styled.div({
  padding: '1rem',
});

const MapSettingsPanel = ({
  user,
  translations: { mapSettingsPanel, solverConfigLabels },
  wizardShown,
}) => {
  const dispatch = useDispatch();
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const {
    display: { showProblem },
  } = useSelector(({ mapContainer }) => mapContainer);
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);

  const [showPanel, setShowPanel] = useState(false);
  const [selectedType, setSelectedType] = useState(OPTIONS.Problem);

  const settings = Object.entries(MAP_SETTINGS[selectedType]).map(([key, value]) => ({
    key,
    value,
  }));
  const lastIteration = solution && last(solution.requests[solution.show].iterations);
  const isSol = isIterationSolution(lastIteration);
  const disabledIndexes = !isSol ? [2] : [];
  const togglePanel = useCallback(() => {
    handleSetUserParam({ openPanel: showPanel ? undefined : PANELS.MAP_SETTINGS });
    setShowPanel(!showPanel);
  }, [showPanel, handleSetUserParam]);

  const handleOnSwitchChange = useCallback(
    (id, value) => {
      const isConcave = id === MAP_SETTINGS[OPTIONS.Solution].SHOW_TOUR_CONCAVE_HULL;
      const showStopsOff = id === MAP_SETTINGS[OPTIONS.Solution].SHOW_STOPS && !value;
      const showConvexHull = id === MAP_SETTINGS[OPTIONS.Solution].SHOW_TOUR_CONVEX_HULL && value;
      const showConcaveHull = isConcave && value;

      const payload = isConcave
        ? {
            ...user.mapSettings[id],
            enabled: value,
            ...(!user.mapSettings[id]?.value && { value: 2 }),
          }
        : value;

      handleSetUserParam({
        mapSettings: {
          ...user.mapSettings,
          [id]: payload,
          ...(showStopsOff && { [MAP_SETTINGS[OPTIONS.Solution].SHOW_CLUSTERS]: false }),
          ...(showConvexHull && {
            [MAP_SETTINGS[OPTIONS.Solution].SHOW_TOUR_CONCAVE_HULL]: false,
          }),
          ...(showConcaveHull && {
            [MAP_SETTINGS[OPTIONS.Solution].SHOW_TOUR_CONVEX_HULL]: false,
          }),
        },
      });
    },
    [handleSetUserParam, user.mapSettings],
  );

  const handleOnChangeInput = useCallback(
    (id, value) => {
      handleSetUserParam({
        mapSettings: {
          ...user.mapSettings,
          [id]: { ...user.mapSettings[id], value },
        },
      });
    },
    [user.mapSettings, handleSetUserParam],
  );

  const onResetDefault = useCallback(() => {
    const resetSettings = Object.keys(MAP_SETTINGS[selectedType]).map(
      (k) => MAP_SETTINGS[selectedType][k],
    );
    const filteredSettings = Object.keys(defaultMapSettings)
      .filter((setting) => resetSettings.includes(setting))
      .reduce((obj, key) => {
        obj[key] = defaultMapSettings[key];
        return obj;
      }, {});

    handleSetUserParam({
      mapSettings: { ...user.mapSettings, ...filteredSettings },
    });
  }, [handleSetUserParam, selectedType, user.mapSettings]);

  const types = Object.keys(OPTIONS).map((key) => ({ key: OPTIONS[key], value: key }));

  const onTypeChange = useCallback(
    (value) => {
      setSelectedType(value);
    },
    [setSelectedType],
  );

  useEffect(() => {
    setShowPanel(user.openPanel === PANELS.MAP_SETTINGS);
  }, [user.openPanel, setShowPanel]);

  useEffect(() => {
    if (!showProblem) onTypeChange(OPTIONS.Solution);
    else onTypeChange(OPTIONS.Problem);
  }, [showProblem, onTypeChange]);

  return (
    <>
      <Modal isDark isVisible={showPanel} isFull fromTop>
        <StyledContrastPanel fullDisplay data-test-id="mapSettingsPanel">
          <StyledContrastPanelHeader size="0">
            <StyledText bold contrast>
              {mapSettingsPanel.mapSettingsTitle}
            </StyledText>
          </StyledContrastPanelHeader>
          <StyledContainer>
            <Section>
              <Selector
                id="button-type-select"
                initialValue={selectedType}
                options={types}
                inverted
                onChange={onTypeChange}
                disabledIndexes={disabledIndexes}
                disabledTitle={mapSettingsPanel.solutionOptionDisabled}
              />
            </Section>
            <Section>
              <StyledContrastPanelColumnsContainer fullModalHeight>
                {settings.map((setting) =>
                  setting.value === MAP_SETTINGS.solution.SHOW_TOUR_CONCAVE_HULL ? (
                    <MultipleActionSetting
                      mapSettingsPanel={mapSettingsPanel}
                      mapSettings={user.mapSettings}
                      setting={setting}
                      handleOnSwitchChange={handleOnSwitchChange}
                      handleOnChangeInput={handleOnChangeInput}
                      key={setting.value}
                    />
                  ) : (
                    <SingleActionSetting
                      mapSettings={user.mapSettings}
                      mapSettingsPanel={mapSettingsPanel}
                      setting={setting}
                      handleOnSwitchChange={handleOnSwitchChange}
                      key={setting.value}
                    />
                  ),
                )}
              </StyledContrastPanelColumnsContainer>
            </Section>
          </StyledContainer>
          <StyledContrastPanelFooter isDouble isFullWidth mapSettings>
            <Button
              text={mapSettingsPanel.resetDefaultSettings}
              inverted
              contrast
              onClick={onResetDefault}
            />
            <div />
            <Button text={solverConfigLabels.buttonDoneLabel} onClick={togglePanel} />
          </StyledContrastPanelFooter>
        </StyledContrastPanel>
      </Modal>
      <StyledAccessContainer
        data-testid="mapSettingsBtn"
        id="map-settings-entry-point"
        onClick={() => togglePanel()}
        order={1}
        icon={mapSettings}
        title={mapSettingsPanel.mapSettingsTitle}
        wizardShown={wizardShown}
      />
    </>
  );
};

export default withTranslation(MapSettingsPanel);
