import moment from 'moment';
import config from 'config';
import { isEmpty, isNaN, isString, size } from 'lodash';
import { CSV_META, ORDER_PRIORITY } from './csv/config';
import { getVRPTimes, getVRPVehicleDefinition } from './VRPConverterTourPlanner';
import { getObjectivesLimit } from './solverConfig/config';
import { groupJobsPerTerritory, isOrderInTerritory } from './territories/TerritoriesHelpers';

const { dateTimeFormat, defaults } = config;
const parseCoordinateValue = (value) =>
  typeof value === 'number' ? value : parseFloat(value.replace(',', '.'));

const parseRowTimes = (row) => {
  const knownDateFormats = [CSV_META.timeFormat];
  const timeRegexStart = /(\d?\d:\d\d)/g;
  const timeRegexEnd = /(\d?\d:\d\d)/g;
  const startTime = timeRegexStart.exec(row.StartTime)[1];
  const endTime = timeRegexEnd.exec(row.EndTime)[1];

  return [moment(startTime, knownDateFormats).format(), moment(endTime, knownDateFormats).format()];
};

const createJobPlaceFromRow = (row, territories) => {
  const place = {
    duration:
      !isNaN(parseInt(row.ServiceTime, 10)) && parseInt(row.ServiceTime, 10) >= 0
        ? parseInt(row.ServiceTime, 10) * 60
        : defaults.serviceTime,
    location: {
      lat: parseCoordinateValue(row.Latitude),
      lng: parseCoordinateValue(row.Longitude),
    },
    ...(row.StartTime && row.EndTime && { times: [parseRowTimes(row)] }),
  };

  const territory = isOrderInTerritory([row], territories);
  if (territory) place.territoryIds = [territory.name];

  return place;
};

const createTasksPerRow = (row, territories) => {
  const activityType = row.Activity;
  const places = [createJobPlaceFromRow(row, territories)];
  const demand =
    !isNaN(parseInt(row.Demand, 10)) && parseInt(row.Demand, 10) > 0
      ? [parseInt(row.Demand, 10)]
      : [defaults.demand];

  const position =
    row?.Position && row?.Position !== 'any'
      ? row.Position === 'first'
        ? { type: 'first' }
        : row.Position === 'last'
        ? { type: 'last' }
        : { type: 'ordered', value: row.PositionValue || row.Position }
      : { type: 'any' };

  const tasks = {};
  if (activityType === 'pickup') tasks.pickups = [{ places, demand, position }];
  else tasks.deliveries = [{ places, demand, position }];
  return tasks;
};

export function mergeJobs(jobs) {
  const mergedJobs = {};

  jobs.forEach((job) => {
    const { id, tasks } = job;

    if (!mergedJobs[id]) {
      mergedJobs[id] = { id, tasks: {} };
    }

    Object.entries(tasks).forEach(([taskType, taskArray]) => {
      if (!mergedJobs[id].tasks[taskType]) {
        mergedJobs[id].tasks[taskType] = [];
      }
      mergedJobs[id].tasks[taskType].push(...taskArray);
    });
  });

  return Object.values(mergedJobs);
}

export function convertParsedCSVToVRPJobs(data = [], isClustered, territories) {
  const filterPudos = data.filter((item) => item.Activity !== 'pudo');
  return filterPudos.map((row) => ({
    id: isClustered ? row.ClusterID : row.InternalID,
    ...(row.Priority ? { priority: row.Priority } : {}),
    ...(isString(row.Skills) && row.Skills.length > 0 ? { skills: [row.Skills] } : {}),
    tasks: createTasksPerRow(row, territories),
  }));
}

const getDepotLocation = (depot) => {
  if (!depot) return null;
  if (depot.lat) return depot;
  if (!depot.tasks) return null;

  const firstTask = depot.tasks.deliveries || depot.tasks.pickups;
  return firstTask[0].places[0].location;
};

const jobsContainPriority = (jobs) => {
  const jobsWithPriority = jobs.filter(
    (job) => job.priority !== undefined && job.priority !== ORDER_PRIORITY.NORMAL,
  );
  return size(jobsWithPriority) > 0;
};

const getObjectiveFunction = (item) => {
  const itemObj = { type: item.key };
  if (item.threshold !== undefined) itemObj.options = { threshold: item.threshold };
  if (item.action !== undefined) itemObj.action = item.action;
  return itemObj;
};

const getObjectiveFunctions = (tourPlanner, jobs, territories, isBeta) => {
  if (!tourPlanner.solverConfiguration.objectiveFunctions.enabled) return null;

  const hasPrioJobs = jobsContainPriority(jobs);
  let allItems = tourPlanner.solverConfiguration.objectiveFunctions.values.filter((v) => v.enabled);
  if (!hasPrioJobs) {
    allItems = allItems.filter((item) => item.key !== 'maximizePriorityJobs');
  } else {
    const index = allItems.findIndex((item) => item.key === 'maximizePriorityJobs');
    if (index === -1) allItems.unshift({ key: 'maximizePriorityJobs', enabled: true });
  }

  if (isEmpty(territories)) {
    allItems = allItems.filter((item) => item.key !== 'maximizeTerritoryJobs');
  } else {
    const index = allItems.findIndex((item) => item.key === 'maximizeTerritoryJobs');
    if (index === -1) allItems.unshift({ key: 'maximizeTerritoryJobs', enabled: true });
  }

  const balanceObjectiveIndex = allItems.findIndex((item) => item.balance === true);
  const hasBalanceObjective = balanceObjectiveIndex !== -1;
  const balanceObjective = allItems.splice(balanceObjectiveIndex, balanceObjectiveIndex + 1)[0];

  const limit = getObjectivesLimit(isBeta);
  const hasTooMany = size(allItems) > limit;
  const extraSize = size(allItems) - limit;
  const cuttingPoint = limit - extraSize - 1;
  const items = hasTooMany ? allItems.slice(0, cuttingPoint) : allItems;
  const transformed = items.map((item) => {
    if (item.key === 'minimizeCost' && hasBalanceObjective)
      return [getObjectiveFunction(item), getObjectiveFunction(balanceObjective)];
    return isBeta ? [getObjectiveFunction(item)] : getObjectiveFunction(item);
  });
  if (hasTooMany) {
    const toAdd = allItems.slice(cuttingPoint);
    const extraItems = toAdd.map((item) => getObjectiveFunction(item));
    let pairs = [];
    extraItems.forEach((item, index) => {
      pairs.push(item);
      if (index % 2 === 0) {
        transformed.push(pairs);
        pairs = [];
      }
    });
  }
  return transformed;
};

const getConfigurations = (tourPlanner) => {
  if (!tourPlanner.solverConfiguration.terminationCriteria.enabled) return null;
  return { termination: tourPlanner.solverConfiguration.terminationCriteria.values };
};

export const getFleetTypeItem = (
  index,
  vehicleAmount,
  vehicleDef,
  depotLocation,
  vrpTimes,
  tourPlanner,
  territories,
  strict,
) => {
  const { start, end } = vrpTimes;
  const idSufix = territories ? territories.map((t) => t.id).join('_') : index;
  const startObj = tourPlanner?.start;
  const endObj = tourPlanner?.end;
  const type = {
    ...vehicleDef,
    amount: vehicleAmount,
    id: vehicleDef.id || `vehicle_${idSufix}`,
    profile: vehicleDef.profile || 'vehicle',
    shifts: [
      {
        start: {
          ...(depotLocation && { location: depotLocation }),
          time: start.format(dateTimeFormat),
          ...(startObj || {}),
        },
      },
    ],
  };

  if (tourPlanner.returnLocation.value) {
    type.shifts[0].end = {
      location: { ...tourPlanner.returnLocation.value },
      time: end.format(dateTimeFormat),
      ...(endObj || {}),
    };
  }

  if (tourPlanner.recharges) {
    type.shifts[0].recharges = tourPlanner.recharges;
  }
  if (tourPlanner.breaks) {
    type.shifts[0].breaks = tourPlanner.breaks;
  }
  if (tourPlanner.restTimes) {
    type.shifts[0].restTimes = tourPlanner.restTimes;
  }
  if (tourPlanner.reloads) {
    type.shifts[0].reloads = tourPlanner.reloads;
  }

  const limits = tourPlanner.vehicles[0].limits;
  if (limits) {
    type.limits = {};

    if (limits.maxDistance?.enabled) {
      type.limits.maxDistance = limits.maxDistance.value;
    } else {
      delete type.limits.maxDistance;
    }

    if (limits.shiftTime?.enabled) {
      type.limits.shiftTime = limits.shiftTime.value;
    } else {
      delete type.limits.shiftTime;
    }
  }

  if (isEmpty(type.limits)) delete type.limits;

  if (!isEmpty(territories)) {
    type.territories = {
      items: territories.map((territory) => ({ id: territory.id, priority: 1 })),
      strict,
    };
  }

  return type;
};

const getFleetTypeItems = (
  isImperial,
  depotLocation,
  vrpTimes,
  tourPlanner,
  territories,
  strict,
) => {
  const hasTerritories = size(territories) > 1;
  const vehicleDef = getVRPVehicleDefinition(tourPlanner, isImperial);

  if (!hasTerritories) {
    const item = getFleetTypeItem(
      1,
      vehicleDef.amount,
      vehicleDef,
      depotLocation,
      vrpTimes,
      tourPlanner,
    );
    return [item];
  }

  const types = [];

  let ordersToFill = 0;
  let selectedTerritories = [];
  let remainigVehicles = vehicleDef.amount;
  let index = 1;

  territories.forEach((territory) => {
    if (remainigVehicles === 0) return;

    ordersToFill += size(territory.orders);
    selectedTerritories.push(territory);
    const requiredVehicles = ordersToFill / vehicleDef.capacity[0];
    if (requiredVehicles < 1 && vehicleDef.amount < size(territories)) return;

    const takenVehicles = Math.min(parseInt(requiredVehicles, 10), remainigVehicles) || 1;
    const item = getFleetTypeItem(
      index,
      takenVehicles,
      vehicleDef,
      depotLocation,
      vrpTimes,
      tourPlanner,
      selectedTerritories,
      strict,
    );
    types.push(item);
    ordersToFill = 0;
    selectedTerritories = [];
    remainigVehicles -= takenVehicles;
    index++;
  });

  if (ordersToFill > 0 && remainigVehicles > 0) {
    const item = getFleetTypeItem(
      index,
      remainigVehicles,
      vehicleDef,
      depotLocation,
      vrpTimes,
      tourPlanner,
      selectedTerritories,
      strict,
    );
    types.push(item);
  }

  return types;
};

function getVehicleProfiles(profiles) {
  return profiles.map((profile) => {
    return {
      name: profile.name,
      type: profile.fleetType,
    };
  });
}

export function generateVRPPayload(jobs, depot, filename, tourPlanner, orders, territories, user) {
  const territoriesGroups = groupJobsPerTerritory(orders, territories.areaDetails, true);
  const depotLocation = getDepotLocation(depot);
  const vrpTimes = getVRPTimes(tourPlanner);
  const groups = tourPlanner?.groups;
  const cfg = tourPlanner?.configuration;
  const types = getFleetTypeItems(
    user.distance === 'imperial',
    depotLocation,
    vrpTimes,
    tourPlanner,
    territoriesGroups,
    territories.strict,
  );
  const profiles = getVehicleProfiles(tourPlanner.vehicleProfiles);
  const vrpPayload = {
    configuration: {},
    fleet: {
      types,
      profiles,
    },
    plan: { jobs },
  };

  if (groups) vrpPayload.plan = { groups, jobs };
  if (cfg) vrpPayload.configuration = cfg;
  else delete vrpPayload.configuration;

  const isBeta = user.tpaVersion.version === 'beta';
  const objectives = getObjectiveFunctions(tourPlanner, jobs, territories.areaDetails, isBeta);

  if (objectives) {
    if (isBeta) {
      vrpPayload.advancedObjectives = objectives;
    } else {
      vrpPayload.objectives = objectives;
    }
  }

  const configuration = getConfigurations(tourPlanner);
  if (configuration) vrpPayload.configuration = configuration;

  return vrpPayload;
}
