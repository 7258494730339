import { isEmpty, isMatch } from 'lodash';
import { getOrdersByStop } from '../OrdersHelpers';
import { getPolygonFromPoints } from './TerritoriesFromJson';
import { calculateBBox } from '../GeoJSONConverter';

export const getPolygonFromBbox = (bbox) => {
  return [
    [bbox[0], bbox[1]],
    [bbox[2], bbox[1]],
    [bbox[2], bbox[3]],
    [bbox[0], bbox[3]],
    [bbox[0], bbox[1]],
  ];
};

export const createRandomTerritories = (bbox) => {
  const mid = (bbox[3] + bbox[1]) / 2;
  const third = (bbox[2] - bbox[0]) / 3;
  const xPoints = [bbox[0], bbox[0] + third, bbox[0] + third * 2, bbox[2]];
  const yPoints = [bbox[1], mid, bbox[3]];
  const territories = [];
  xPoints.forEach((x, iX) => {
    yPoints.forEach((y, iY) => {
      if (!xPoints[iX + 1] || !yPoints[iY + 1]) return;
      const newBbox = [x, y, xPoints[iX + 1], yPoints[iY + 1]];
      const coords = getPolygonFromBbox(newBbox);
      const index = iX * 2 + iY;
      const name = `Area${index + 1}`;
      territories.push({ polygon: coords, bbox: newBbox, index, name });
    });
  });
  return territories;
};

export const getTerritoriesGeoJSON = (...territories) => {
  const tourData = territories.pop();
  const groups = tourData?.plan?.groups;

  const filteredTerritories = territories
    .flat()
    .filter((territory) => territory && territory.bbox && territory.polygon);
  if (filteredTerritories.length === 0) return null;

  const features = filteredTerritories.map((territory) => ({
    type: 'Feature',
    bbox: territory.bbox,
    geometry: {
      coordinates: [territory.polygon],
      type: 'Polygon',
    },
    properties: {
      index: territory.index,
      name: territory.name,
      type: territory.type,
      groupName: groups && groups[territory.index]?.id,
      vehicleId: tourData?.tours?.[territory.index]?.vehicleId,
    },
  }));

  return { features };
};

export const isOrderInTerritory = (orders, territories) => {
  if (!orders || isEmpty(orders) || !territories || isEmpty(territories)) return null;

  return territories.find((terr) => {
    return terr.orders.some((order) => orders.some((o) => isMatch(o, order)));
  });
};

export const groupJobsPerTerritory = (jobs, territories, onlyWithJobs) => {
  if (isEmpty(jobs) || isEmpty(territories)) return null;

  const groups = territories.map((territory) => ({ id: territory.name, orders: [] }));
  jobs.forEach((job) => {
    const territory = isOrderInTerritory([job], territories);
    if (territory) {
      const group = groups.find((item) => item.id === territory.name);
      group.orders.push(job);
    }
  });
  return onlyWithJobs ? groups.filter((item) => !isEmpty(item.orders)) : groups;
};

export const getValidOrdersForAreas = (tours, ...areaDetails) => {
  if (!tours || isEmpty(tours) || !areaDetails) return [];
  const allOrders = [
    ...areaDetails
      .flat()
      .filter((area) => area && area.orders)
      .flatMap((area) => area.orders),
  ];
  return tours
    .flatMap((tour) => tour.stops.flatMap((stop) => getOrdersByStop(allOrders, stop)))
    .filter((orders) => orders && !isEmpty(orders));
};

export const updateAreaDetails = (areaDetails, filteredOrders) => {
  if (!areaDetails || !filteredOrders || isEmpty(filteredOrders)) return areaDetails;
  return areaDetails.reduce((acc, area) => {
    const newOrders = area.orders.filter((order) => filteredOrders.some((o) => isMatch(o, order)));

    if (isEmpty(newOrders)) return acc;

    const coords = newOrders.map((order) => [order.Longitude, order.Latitude]);
    const polygon = getPolygonFromPoints(coords);
    const bbox = calculateBBox(coords, 10);

    acc.push({ ...area, orders: newOrders, polygon, bbox });
    return acc;
  }, []);
};

export const filterAreasByType = (areas, areaType) => {
  if (!areas || isEmpty(areas) || !areaType) return [];
  return areas.filter((area) => area.type === areaType);
};
