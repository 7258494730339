import { isMobileDevice } from '../helpers';
import { colors, tourColors } from '../../global/variables';
import { toBounds } from './MapCalculations';

const { unusedMarkerStroke, routeGradientStart, routeGradientEnd, unselectedTourColor } = colors;

export const getMapSettings = () =>
  isMobileDevice()
    ? { paddingTopLeft: [50, 0], paddingBottomRight: [80, 0] }
    : { paddingTopLeft: [450, 100], paddingBottomRight: [40, 40] };

export const animateFlyTo = {
  animation: true,
  duration: 1.111,
  easeLinearity: 0.69,
};

export const divideColors = (c1, c2, index, steps) => {
  if (!c1 || !c2 || !steps) return [];

  c1 = c1.match(/\d+/g).map(Number);
  c2 = c2.match(/\d+/g).map(Number);

  const colorArray = [];
  for (let i = 0; i < 3; i++) {
    colorArray[i] = Math.round(c1[i] + (index / steps) * (c2[i] - c1[i]));
  }

  return `rgb(${colorArray[0]},${colorArray[1]},${colorArray[2]})`;
};

export const getStyle = (feature, gradientRoutes, type, showOverlaps) => {
  const {
    properties: {
      routeId,
      mode,
      isStopsRequest,
      fromStop,
      totalStops,
      isUndesiredLine,
      colorGradient,
    },
  } = feature;

  const vehicleType = type || mode;
  const isPedestrian = vehicleType === 'pedestrian';
  const weight = isPedestrian ? 1 : 2;
  const dashArray = isPedestrian ? '2 6' : undefined;
  const color =
    isUndesiredLine && showOverlaps
      ? colorGradient
      : tourColors[routeId % tourColors.length].delivery.inner;
  const gradientColor = divideColors(routeGradientStart, routeGradientEnd, fromStop, totalStops);

  const routeColor = gradientRoutes && isStopsRequest ? gradientColor : color;

  return {
    opacity: 1,
    weight,
    color: routeColor,
    dashArray,
  };
};

export const getStyleUnselected = () => ({ opacity: 1, weight: 1.5, color: unselectedTourColor });
export const getStyleOriginalLocationLine = () => ({
  opacity: 0.8,
  weight: 2,
  color: unusedMarkerStroke,
  dashArray: '4',
});
export const fitMapBounds = (map, bounds) => {
  map.fitBounds(toBounds(bounds), getMapSettings(), animateFlyTo);
};
