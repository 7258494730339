import FileSaver from 'file-saver';
import moment from 'moment';
import { isEmpty, keys, uniqBy } from 'lodash';
import { isSameLocation } from './helpers';
import {
  getLabelsFromOrders,
  getOrdersAddress,
  getOrdersByLocation,
  getOrdersByStop,
} from './OrdersHelpers';
import config from '../config';
import { getSafeValue } from './security';
import { getBatchLocationAddress, getLocationAddress } from './GeoCoder';
import { getVehicleProfile } from './FleetHelpers';
import { isBreakOrReload, isDepartureOrArrival } from './SolutionHelpers';
import { getMode } from './deeplink';

export const getGenericFilename = (user, prefix, base, ext) => {
  const original = user && user.lastImport && user.lastImport.filename;
  const startContent = (original || base).replace('.csv', '');
  const start = !isEmpty(startContent) ? ` ${startContent}` : '';
  const suffix = original ? '' : ` ${moment().format('YYYY-MM-DD')}`;
  return `${prefix}${start}${suffix}.${ext}`.replace(/ /g, '_');
};

export const getFileName = (user, index, base, ext) => {
  const tour = index || 'Plan';
  const prefix = `Tour_${tour}_`;
  return getGenericFilename(user, prefix, base, ext);
};

export const getPDFFileName = (user, index) => {
  return getFileName(user, index, config.name, 'pdf');
};

export const getFileDelimiter = (user) => {
  const original = user && user.lastImport && user.lastImport.delimiter;
  return original || ',';
};

function getStopPhone(stop, orders) {
  const stopOrders = getOrdersByStop(orders, stop);
  if (!stopOrders || isEmpty(stopOrders)) return null;
  if (stopOrders.length === 1) return stopOrders[0].Phone;
  const withPhone = uniqBy(stopOrders, (o) => o.Phone).filter((o) => o.Phone);
  return withPhone && withPhone.length === 1 && withPhone[0].Phone;
}

export function saveToJsonFile(solution, name) {
  const blob = new Blob([JSON.stringify(solution)], { type: 'application/json' });
  return FileSaver.saveAs(blob, `${name}`);
}

export function makeNavLink(
  endLocation,
  startLocation,
  language,
  stop,
  orders,
  tourPlanner,
  fleetType,
) {
  if (!startLocation || !endLocation) return null;

  const baseUrl = config.share.url;
  const params = { lang: language };
  const phone = getStopPhone(stop, orders);
  if (phone && !isEmpty(phone)) params.tel = phone;
  if (tourPlanner && !isEmpty(tourPlanner.companyName)) params.company = tourPlanner.companyName;
  const mappedParams = keys(params).map(
    (k) => `${k}=${encodeURIComponent(getSafeValue(params, k))}`,
  );
  return startLocation
    ? `${baseUrl}/${startLocation}/${endLocation}${getMode(fleetType)}&${mappedParams.join('&')}`
    : null;
}

export function makeDesktopNavLink(stop, prevStop, language, orders, tourPlanner, fleetType) {
  const stopLoc = `${stop.location.lat},${stop.location.lng}`;
  const prevStopLoc = prevStop ? `${prevStop.location.lat},${prevStop.location.lng}` : null;
  return makeNavLink(stopLoc, prevStopLoc, language, stop, orders, tourPlanner, fleetType);
}

export function makeShareNavLink(stop, language, orders, tourPlanner, fleetType) {
  const stopLoc = `${stop.location.lat},${stop.location.lng}`;
  return makeNavLink(stopLoc, 'mylocation', language, stop, orders, tourPlanner, fleetType);
}

export function createTourShareText(tour, request, translations, tourPlanner, user, fleetType) {
  if (!tour || !request) return null;

  const stopsText = tour.stops.map((stop, index) => {
    const isDepot = index === 0;
    const isReturnLocation =
      index === tour.stops.length - 1 && tourPlanner && tourPlanner.returnLocation.value !== null;
    let tourPlannerLabel = null;
    if (isDepot) {
      tourPlannerLabel = tourPlanner && tourPlanner.value && tourPlanner.location.label;
      if (!tourPlannerLabel) {
        const ordersForDepot = getOrdersByLocation(request.orders, stop.location);
        tourPlannerLabel = !isEmpty(ordersForDepot) ? ordersForDepot[0].Address : null;
      }
    }
    if (isReturnLocation) {
      tourPlannerLabel = isSameLocation(tourPlanner.location, tourPlanner.returnLocation)
        ? translations.arrivalTrans
        : tourPlanner.returnLocation.label;
    }
    const orders = getOrdersByStop(request.orders, stop);
    const labels = getLabelsFromOrders(orders, true, translations);
    const address = getOrdersAddress(orders);
    const defaultVal = isDepot ? null : `${translations.stopTrans} ${index}`;
    const defaultLabel =
      defaultVal || (isDepot ? translations.depotMarkerTrans : translations.arrivalTrans);
    const label = address || tourPlannerLabel || defaultLabel;
    const details = (labels && labels.join('\n')) || '';
    const shareLink =
      !isDepot && makeShareNavLink(stop, user.language, request.orders, tourPlanner, fleetType);
    const time = moment(stop.time.arrival).format('LT');
    const order = !isDepot && !isReturnLocation ? `${index}.  ` : '';
    const header = `${order}${time}`;
    const elements = [header, label, shareLink, details].filter((e) => !!e);
    return elements.join('\n');
  });

  const titleArr = [translations.toursDetailsTitle];
  if (tourPlanner && !isEmpty(tourPlanner.companyName)) titleArr.unshift(tourPlanner.companyName);
  const title = titleArr.join(' - ');

  return `${title}\n\n${stopsText.join('\n')}`;
}

export function createTourShareData(tour, request, translations, tourPlanner, user, fleetType) {
  const maxLinkLength = 1800;
  const titleArr = [config.name];
  if (tourPlanner && !isEmpty(tourPlanner.companyName)) titleArr.unshift(tourPlanner.companyName);
  const title = titleArr.join(' & ');

  const text = createTourShareText(tour, request, translations, tourPlanner, user, fleetType);
  let link = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(text)}`;
  if (link.length > maxLinkLength) {
    link = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(
      translations.pasteTextHint,
    )}`;
  }
  return {
    link,
    text,
  };
}

const orderHeadersToExport = [
  'tour',
  'stop',
  'id',
  'name',
  'address',
  'phone',
  'email',
  'notes',
  'demand',
  'eta',
  'latitude',
  'longitude',
  'shareLink',
];

function getTourRowsToExport(tour, request, tourPlanner, user, tourIndex, fleetType) {
  if (!tour || !request) return null;

  const toExport = [];
  tour.stops.forEach((stop, index) => {
    const isArrival =
      index === tour.stops.length - 1 && tourPlanner && tourPlanner.returnLocation.value !== null;
    if (index === 0 || isArrival) return;

    const orders = getOrdersByStop(request.orders, stop);
    const shareLink = makeShareNavLink(stop, user.language, request.orders, tourPlanner, fleetType);
    const eta = moment(stop.time.arrival).format('LT');
    orders.forEach((order) => {
      const orderToExport = {
        tour: tourIndex,
        stop: index,
        id: order.InternalID,
        name: order.Name,
        address: order.Address,
        phone: order.Phone,
        email: order.Email,
        notes: order.Notes,
        demand: order.Demand,
        eta,
        latitude: order.Latitude,
        longitude: order.Longitude,
        shareLink,
      };
      toExport.push(orderToExport);
    });
  });
  return toExport;
}

function getToursToExport(tours, request, tourPlanner, user, initialTourIndex) {
  let toExport = [];
  tours.forEach((tour, index) => {
    const tourRows = getTourRowsToExport(
      tour,
      request,
      tourPlanner,
      user,
      index + initialTourIndex,
    );
    if (tourRows) toExport = toExport.concat(tourRows);
  });
  return toExport;
}

export const prepareValueToExport = (value) => {
  if (!value) return '';
  return `"${value
    .toString()
    .replace(/(?:\r\n|\r|\n)/g, '. ')
    .replace(/"/g, "'")}"`;
};

export function exportTourToCSV(tour, request, tourPlanner, user, tourIndex, fleetType) {
  const toExport = getTourRowsToExport(tour, request, tourPlanner, user, tourIndex, fleetType);
  if (!toExport) return null;

  const headers = orderHeadersToExport;
  const delimiter = getFileDelimiter(user);
  const rows = toExport.map((order) => {
    const values = headers.map((h) => prepareValueToExport(getSafeValue(order, h)));
    return `${values.join(delimiter)}\n`;
  });

  const csv = [`${headers.join(delimiter)}\n`, ...rows];
  const blob = new Blob(csv, { type: 'text/csv;charset=utf-8;' });
  const fileName = getFileName(user, tourIndex, config.name, 'csv');
  return FileSaver.saveAs(blob, fileName);
}

export function exportToursToCSV(tours, request, tourPlanner, user, initialTourIndex) {
  const toExport = getToursToExport(tours, request, tourPlanner, user, initialTourIndex);
  if (!toExport) return null;

  const headers = orderHeadersToExport;
  const delimiter = getFileDelimiter(user);
  const rows = toExport.map((order) => {
    const values = headers.map((h) => prepareValueToExport(getSafeValue(order, h)));
    return `${values.join(delimiter)}\n`;
  });

  const csv = [`${headers.join(delimiter)}\n`, ...rows];
  const blob = new Blob(csv, { type: 'text/csv;charset=utf-8;' });
  const fileName = getFileName(user, null, config.name, 'csv');
  return FileSaver.saveAs(blob, fileName);
}

function getActivitiesMobileJSON(stop, oAuth, orders, user) {
  const activityLocations = stop.activities.map((activity) => activity.location ?? stop.location);
  return getBatchLocationAddress(activityLocations, oAuth, user.politicalView).then((addresses) => {
    let orderIndex = -1;

    return stop.activities.map((activity, index) => {
      const address = addresses[index];
      if (!isDepartureOrArrival(activity.jobId) && !isBreakOrReload(activity.jobId)) orderIndex++;

      const street =
        address.street && address.houseNumber && `${address.street} ${address.houseNumber}`;
      const result = {
        location: activity.location ?? stop.location,
        address: {
          street,
          city: address.city,
          countryCode: address.countryCode,
          zipCode: address.postalCode,
        },
        jobId: activity.jobId,
        id: activity.jobId,
        externalId: activity.jobId,
        type: activity.type,
        status: 'accepted',
        recipient: {
          address: {
            street,
            city: address.city,
            countryCode: address.countryCode,
            zipCode: address.postalCode,
          },
        },
      };

      if (!isEmpty(orders) && orderIndex >= 0) {
        if (orders[orderIndex].Name) result.recipient.businessName = orders[orderIndex].Name;
        if (orders[orderIndex].Phone) result.recipient.phoneNumber = orders[orderIndex].Phone;
      }

      if (activity.time) {
        result.schedule = {
          arrival: activity.time.start,
          departure: activity.time.end,
        };
      }

      return result;
    });
  });
}

function getStopsMobileJSON(tour, oAuth, orders, user) {
  return tour.stops.map((stop) => {
    const ordersInStop = getOrdersByStop(orders, stop);
    return getActivitiesMobileJSON(stop, oAuth, ordersInStop, user).then((activities) => {
      return getLocationAddress(stop.location, oAuth, user).then((address) => {
        const stopAddress = address.address;
        const street =
          stopAddress.street &&
          stopAddress.houseNumber &&
          `${stopAddress.street} ${stopAddress.houseNumber}`;
        return {
          location: stop.location,
          address: {
            street,
            city: stopAddress.city,
            countryCode: stopAddress.countryCode,
            zipCode: stopAddress.postalCode,
          },
          schedule: {
            arrival: stop.time.arrival,
            departure: stop.time.departure,
          },
          activities,
        };
      });
    });
  });
}

export function exportTourToMobileJSON(tour, fleet, oAuth, orders, user) {
  return Promise.all(getStopsMobileJSON(tour, oAuth, orders, user)).then((stops) => {
    return {
      id: tour.vehicleId,
      version: 1,
      versionState: 'accepted',
      vehicle: {
        id: tour.vehicleId,
        location: tour.stops[0].location,
        address: 'Invalidenstraße 116, 10115 Berlin',
        registrationNumber: tour.vehicleId,
        profile: getVehicleProfile(tour.typeId, fleet.types, fleet.profiles).type,
      },
      scheduledDate: tour.stops[0].time.departure,
      status: 'assigned',
      stops,
      driverId: tour.vehicleId,
      departureDate: tour.stops[0].time.departure,
    };
  });
}
