import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveToJsonFile } from 'utils/SolutionExport';
import { integrateFileText, planTour } from 'utils/apiFilesUploadHelpers';
import { useCookies } from 'react-cookie';
import { last } from 'lodash';
import { getUseAsync, setSolutionParam } from 'actions';
import { shouldRememberLastState } from 'utils/MemoryHelpers';
import withTranslation from '../../../hoc/withTranslation';
import { StyledContrastPanelFooter } from '../../Wizard/Global/WizardStyledContrastPanel';
import Button from '../../Form/Button';
import { METHODS } from './DevConsoleHeader';
import { isProdEnv } from '../../../utils/helpers';
import {
  getNumberOfJobs,
  hasOngoingAsyncRequest,
  isIterationSolution,
} from '../../../utils/SolutionHelpers';

const isProd = isProdEnv();

const OPTIONS = {
  PROBLEM: 'problem',
  SOLUTION: 'solution',
  GEO: 'geoJson',
  INTERNAL_TOUR_PLANNER: 'tourPlanner',
  INTERNAL_USER: 'user',
  INTERNAL_ITERATIONS: 'iterations',
};

const DevConsoleFooter = ({
  setIsJsonUploadClicked,
  selectedOption,
  jsonObject,
  tourPlanner,
  oAuth,
  handleSetError,
  translations: {
    error,
    devConsoleLabels,
    tourPlanner: { submitTrans, jsonApiKey },
    map: { returnLocationMarkerTrans, depotMarkerTrans },
  },
}) => {
  const dispatch = useDispatch();
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const orders = useSelector((state) => state.orders[solution.show]);
  const [cookies] = useCookies(['apikey']);
  const user = useSelector(({ user: stateUser }) => stateUser);
  const loader = useSelector(({ loader: stateLoader }) => stateLoader);
  const [fileName, setFileName] = useState();
  const [hasProblem, setHasProblem] = useState();
  const handleSetSolutionParameter = useCallback(
    (data) => dispatch(setSolutionParam(data)),
    [dispatch],
  );

  const hasNoApiKey = isProd && (!cookies.apikey || cookies.apikey === '');
  const isDemoMode = tourPlanner.ordersMode === 'demo';
  const hasDepot = !!(tourPlanner && tourPlanner.location && tourPlanner.location.value);
  const minOrders = hasDepot ? 1 : 2;
  const hasOrders = orders && orders.length >= minOrders;
  const isInitialJson = jsonObject && jsonObject.plan?.jobs.length === 0;
  const isSolution =
    solution && isIterationSolution(last(solution.requests[solution.show].iterations));
  const manualFlow = !isSolution && tourPlanner.ordersMode !== 'json_import';
  const isDisabled = !isProdEnv()
    ? loader.isLoading || isInitialJson || !hasProblem || manualFlow
    : (!solution.json && !solution.jsonUserChange) ||
      loader.isLoading ||
      (hasNoApiKey && !isDemoMode && !hasOrders) ||
      hasNoApiKey;
  const uploadDisabled = loader.isLoading || hasOngoingAsyncRequest(solution.requests);

  const handleDownloadClick = useCallback(() => {
    const lastIteration = solution && last(solution.requests[solution.show].iterations);
    const isGeo = selectedOption === OPTIONS.GEO;
    const geoJson = isGeo && lastIteration.geoJSON.geo;
    const solJson = lastIteration.response;
    const toDownload = isGeo ? geoJson : selectedOption === OPTIONS.SOLUTION ? solJson : jsonObject;
    const solFileName = `${fileName}_solution`;
    const problemName = solution.requests[solution.show]?.problemFilename || fileName;

    if (geoJson) toDownload.type = 'FeatureCollection';

    const fileToDownload = isGeo
      ? `${solFileName}_geo.json`
      : selectedOption === OPTIONS.SOLUTION
      ? `${solFileName}.json`
      : problemName;

    if (toDownload) saveToJsonFile(toDownload, fileToDownload);
  }, [solution, selectedOption, jsonObject, fileName]);

  const handlePlanTour = useCallback(() => {
    if (!solution.json)
      handleSetSolutionParameter({ param: { name: fileName }, index: solution.show });
    if (!shouldRememberLastState())
      handleSetSolutionParameter({ param: { tempOrders: orders }, index: solution.show });

    handleSetError();
    const lastIterationRequest =
      solution && last(solution.requests[solution.show].iterations).request;
    const file = lastIterationRequest?.fileObj || lastIterationRequest?.json;
    let timesPlanned = (solution && solution.requests[solution.show].timesPlanned) || 0;
    const useAsync = getUseAsync(
      user.requestType,
      getNumberOfJobs(file.plan.jobs),
      file.fleet.types.length,
    );
    const hasManualJobs =
      orders.some((order) => order.InternalID.startsWith('manual-')) && tourPlanner.editedOrder;

    if (timesPlanned > 0) {
      if (useAsync)
        handleSetSolutionParameter({
          param: { status: null, statusHref: null },
          index: solution.show,
        });

      const isDemo = tourPlanner.ordersMode === 'demo' || user.isLastOrdersAddedDemo;

      integrateFileText(
        JSON.stringify(file),
        'upload',
        'pasted',
        user,
        cookies,
        oAuth,
        dispatch,
        true,
        error,
        solution.show,
        false,
        true,
        isDemo,
        depotMarkerTrans,
        returnLocationMarkerTrans,
      );
    }

    planTour({
      dispatch,
      mode: 'upload',
      isSolution: !!file.tours,
      cookies,
      user,
      oAuth,
      fileObj: file,
      index: solution.show,
      keepOrders: tourPlanner.ordersMode !== 'json_import' || hasManualJobs,
      timesPlanned: (timesPlanned += 1),
    });
  }, [
    dispatch,
    cookies,
    user,
    oAuth,
    solution,
    handleSetError,
    tourPlanner,
    error,
    handleSetSolutionParameter,
    fileName,
    orders,
    depotMarkerTrans,
    returnLocationMarkerTrans,
  ]);

  useEffect(() => {
    const lastReqName = (solution && solution.requests[solution.show]?.name) || '';
    const slicedName = lastReqName.endsWith('.json')
      ? lastReqName.slice(0, -5)
      : lastReqName.endsWith('.csv')
      ? lastReqName.slice(0, -4)
      : lastReqName;
    const methodsArr = Object.keys(METHODS).map((k) => METHODS[k]);

    setFileName(
      lastReqName && !methodsArr.includes(lastReqName) ? slicedName : tourPlanner.ordersMode,
    );
  }, [solution, tourPlanner.ordersMode]);

  useEffect(() => {
    const lastIteration = solution && last(solution.requests[solution.show].iterations);
    setHasProblem(!isSolution || (isSolution && lastIteration.request.json.fleet.types));
  }, [setHasProblem, isSolution, solution]);

  return (
    <StyledContrastPanelFooter data-test-id="jsonViewerPlanTour" isDouble isFullWidth jsonViewer>
      <Button
        inverted
        contrast
        disabled={isDisabled}
        text={submitTrans}
        title={solution.json && hasNoApiKey ? jsonApiKey : ''}
        panel
        onClick={handlePlanTour}
        dataTestId="JVPlanTour"
      />
      <Button
        text={devConsoleLabels.uploadJson}
        panel
        disabled={uploadDisabled}
        onClick={() => setIsJsonUploadClicked(true)}
        dataTestId="JVUploadJson"
      />
      <Button text={devConsoleLabels.buttonDownloadLabel} onClick={handleDownloadClick} panel />
    </StyledContrastPanelFooter>
  );
};

export default withTranslation(DevConsoleFooter);
