import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, useMap } from 'react-leaflet';
import withTranslation from 'hoc/withTranslation';
import {
  recordUsageEvent,
  selectTourById,
  setOrders,
  setSolutionJson,
  setUserParam,
  USAGE_EVENTS,
} from 'actions';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from '@emotion/styled';
import { getLocationAddress } from 'utils/GeoCoder';
import { v4 } from 'uuid';
import config from '../../config';

const StyledLink = styled.li({
  fontSize: '14px',
  cursor: 'pointer',
  color: 'black',
  ':hover': {
    backgroundColor: '#D3D3D3',
  },
});

const StyledName = styled.span({
  display: 'block',
  color: 'black',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledList = styled.ul({
  paddingLeft: '15px',
});

const { emptyOrder } = config;

const LocationSelector = ({
  oAuth,
  translations: {
    locationPopup: { copyToClipboard, addPickup, addDelivery },
  },
  display,
}) => {
  const map = useMap();
  const dispatch = useDispatch();
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const orders = useSelector((state) => state.orders[solution.show]);
  const user = useSelector(({ user: stateUser }) => stateUser);
  const tourPlanner = useSelector(
    ({ tourPlanner: stateTourPlanner }) => stateTourPlanner.value[solution.show],
  );

  const handleRecordUsage = useCallback((data) => dispatch(recordUsageEvent(data)), [dispatch]);
  const handleSetOrders = useCallback(
    (newOrders) => dispatch(setOrders(newOrders, false, solution.show)),
    [dispatch, solution.show],
  );
  const handleSelectTourById = useCallback((data) => dispatch(selectTourById(data)), [dispatch]);
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);
  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const [position, setPosition] = useState(null);
  const [address, setAddress] = useState('');

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(address);
    toast('Copied Address to clipboard!', {
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
    });
    setPosition(null);
  };

  const onAddAddress = useCallback(
    (type) => {
      handleSetUserParam({ jsonPanelOpen: true });
      const id = `manual-${v4()}`;
      const name =
        tourPlanner.editedOrder?.Name?.length > 0 ? tourPlanner.editedOrder.Name : emptyOrder.Name;
      const demand = tourPlanner.editedOrder?.Demand || [1];
      const newOrders = [
        ...orders,
        {
          ...emptyOrder,
          Address: address,
          Latitude: position.lat,
          Longitude: position.lng,
          Activity: type,
          InternalID: id,
          ID: id,
          TimeCreated: Date.now(),
          Demand: demand,
          Name: name,
          Position: 'any',
        },
      ];
      handleSelectTourById({ selectedTour: display.selectedTour, noMapMovement: true });
      handleSetOrders(newOrders);
      handleSetSolutionJson({ jsonTriggerVRP: true });
      handleRecordUsage({ event: USAGE_EVENTS.ORDERS_ADD, mode: 'manual' });
      setPosition(null);
    },
    [
      position,
      handleSetOrders,
      handleSetSolutionJson,
      orders,
      address,
      tourPlanner.editedOrder,
      handleSetUserParam,
      display,
      handleSelectTourById,
    ],
  );

  useEffect(() => {
    map.on('contextmenu', (e) => {
      getLocationAddress(e.latlng, oAuth, user).then((location) => {
        setPosition(e.latlng);
        setAddress(location.title);
      });
    });

    return () => {
      setPosition(null);
      setAddress('');
    };
  }, []);

  return position ? (
    <Popup className="location-markers-popup" position={position} autoClose={false}>
      <StyledName title={address}>{address}</StyledName>
      <StyledList>
        <StyledLink onClick={() => onAddAddress('pickup')} data-test-id="addAddressAsPickup">
          {addPickup}
        </StyledLink>
        <StyledLink onClick={() => onAddAddress('delivery')} data-test-id="addAddressAsDelivery">
          {addDelivery}
        </StyledLink>
        <StyledLink onClick={onCopyToClipboard}>{copyToClipboard}</StyledLink>
      </StyledList>
      <ToastContainer hideProgressBar closeButton={false} />
    </Popup>
  ) : (
    <></>
  );
};

export default withTranslation(LocationSelector);
