import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, keys, reduce, size } from 'lodash';
import { canDownloadCSV, getAsset, getAssignedJobs } from 'utils/helpers';
import {
  getLastValidIteration,
  getTotalJobsInTours,
  getTypeCount,
  getVehicleTypeCount,
} from 'utils/SolutionHelpers';
import { convertCostsFromTourPlanner } from 'utils/cost';
import jobIcon from 'global/img/mini-order.svg';
import unassignedJobIcon from 'global/img/mini-order-unassigned.svg';
import Popup from 'components/Popup/Popup';
import downloadIcon from '../../../global/img/download.svg';
import downloadIconContrast from '../../../global/img/download-contrast.svg';
import viewIcon from '../../../global/img/details.svg';
import viewIconContrast from '../../../global/img/details-contrast.svg';
import { StyledButtonSmall } from '../Global/WizardStyled';
import { saveUnassignedToCSVFile } from '../../../utils/SolutionExportToCsv';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { increaseUserUsage } from '../../../actions';
import { exportToursToCSV } from '../../../utils/SolutionExport';
import { getUrlInitialTourIndex } from '../../../utils/urlHelpers';
import { getFleetTypeIcon } from '../../../utils/FleetHelpers';
import { getSafeValue, setSafeValue } from '../../../utils/security';
import { formatPriceWithCurrency } from '../../../utils/formatter';
import SummaryElement from './SummaryElement';
import SummaryRow from './SummaryRow';

const { small, normal } = fontSize;
const initialTourIndex = getUrlInitialTourIndex();

const StyledSummary = styled.div`
  max-height: 10.7rem;
  margin-bottom: 0.5rem;
`;

export const StyledTitle = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin: 0 0.6rem 0 0;
  font-size: ${small};
  opacity: 0.75;
  display: inline-block;
`;

export const StyledText = styled.strong`
  font-size: ${normal};
  font-weight: ${(props) => props.normal && '400'};
  line-height: ${(props) => (props.small ? '1.2rem' : '2rem')};
  display: block;
  border-bottom: ${(props) => (props.borderBottom ? '0.06rem dotted black' : 'none')};
  margin-bottom: ${(props) => (props.borderBottom ? '0.6rem' : 'auto')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledSpan = styled.span`
  font-size: ${small};
  cursor: pointer;
`;
const StyledVehicleIconSize = '1.25rem';

const jobIconStyle = css`
  background-image: url(${getAsset(jobIcon)});
  width: 22px;
  height: 22px;
  background-size: 20px;
  cursor: pointer;
`;

const unassignedJobIconStyle = css`
  background-image: url(${getAsset(unassignedJobIcon)});
  width: 22px;
  height: 22px;
  background-size: 20px;
  cursor: pointer;
`;

const StyledIcon = styled.div(
  {
    width: '.4rem',
    height: '.4rem',
    backgroundSize: '.4rem .4rem',
    display: 'inline-block',
    backgroundRepeat: 'no-repeat',
    marginRight: '0',
    paddingRight: '10px',
    ':first-of-type': {
      padding: 0,
    },
  },
  ({ icon }) => ({
    ...icon,
  }),
);

const StyledIconWrapper = styled.div(
  {
    width: '.4rem',
    height: '.4rem',
    backgroundSize: '.4rem .4rem',
    display: 'flex',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    marginRight: '0',
    paddingRight: '10px',
    ':first-of-type': {
      padding: 0,
    },
  },
  ({ icon }) => ({
    ...icon,
  }),
);

const DownloadIconContrastStyle = css`
  position: relative;
  background-image: url(${getAsset(downloadIconContrast)});
  width: 1.1rem !important;
  height: 1.1rem !important;
  background-size: 0.9rem !important;
  background-position-y: 0px;
  right: -3px;
`;

const DownloadIcon = css`
  position: relative;
  background-image: url(${getAsset(downloadIcon)});
  width: 1.1rem !important;
  height: 1.1rem !important;
  background-size: 0.9rem !important;
  background-position-y: 0px;
  right: -3px;
`;

const ViewIconStyle = (isToursView) => css`
  position: relative;
  background-image: url(${getAsset(isToursView ? viewIcon : viewIconContrast)});
  width: 1rem !important;
  height: 1rem !important;
  background-size: 1rem !important;
  background-position-y: 2px;
  float: right;
  right: -2px;
`;

const StyledDetailContainer = styled.div(({ noMarginBottom }) => ({
  marginRight: '1rem',
  marginTop: '-0.8rem',
  marginBottom: !noMarginBottom && '2rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const StyledWrapper = styled.div(({ typeCount }) => ({
  paddingBottom: typeCount === 1 ? '4rem' : typeCount === 2 ? '2rem' : 0,
}));

const StyledDownloadContainer = styled.div(({ noMarginTop }) => ({
  marginTop: !noMarginTop && '0.8rem',
}));

const StyledReview = styled(StyledButtonSmall)`
  margin-right: 7px;
  text-transform: uppercase;
`;

const SummaryWithDownload = ({
  orders,
  tourPlanner,
  user,
  solution,
  lastIterationTourData: { fleet, tours, unassigned, uploaded, statistic, plan },
  onChangeView,
  isToursView,
  helpers: { formatDuration },
  translations: {
    wizard: {
      toursDetails: { co2Trans, consumptionExplTrans, co2Explanation, consumptionExplUnits },
      download,
      tours: { unassignedDownloadTitle, unassignedView, toursView },
    },
    tourSolution: {
      costTrans,
      distanceTrans,
      durationTrans,
      jobTrans,
      vehicleTypes,
      preposition,
      clickForInfo,
      consumptionTransTotal,
      units: {
        distance: { kilo, mile },
      },
    },
  },
}) => {
  const isImperial = user.distance === 'imperial';
  const totalTime = statistic.duration
    ? formatDuration(Math.floor(statistic.duration / 60))
    : 'Unknown';
  const totalCost = statistic.cost;
  const totalCnsmp = statistic.consumption?.toFixed(2);
  const totalCO2Emission = statistic.co2Emission?.toFixed(2);

  const dispatch = useDispatch();
  const handleIncreaseUsage = useCallback((data) => dispatch(increaseUserUsage(data)), [dispatch]);
  const [numberOfVehicles, setNumberOfVehicles] = useState(0);
  const [totalNumberOfVehicles, setTotalNumberOfVehicles] = useState(0);
  const [numberOfUnassigned, setNumberOfUnassigned] = useState(0);
  const [numberOfAssigned, setNumberOfAssigned] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [totalDistance, setTotalDistance] = useState(0);

  const showTotalVehicles = isEmpty(fleet);

  const request = solution && getLastValidIteration(solution.requests[0]).request;
  const typeCount = getTypeCount(tours);

  useEffect(() => {
    const vehicles = size(
      keys(
        reduce(
          tours,
          (acc, { vehicleId }) => {
            setSafeValue(acc, vehicleId, true);
            return acc;
          },
          {},
        ),
      ),
    );

    const definedVehicles =
      uploaded || isEmpty(fleet)
        ? 0
        : fleet.types.reduce(
            (total, type) =>
              total + (type.amount !== undefined ? type.amount : type.vehicleIds.length),
            0,
          );
    const totalVehicles = showTotalVehicles ? vehicles : definedVehicles;
    const unassignedJobs = size(keys(unassigned));

    setNumberOfVehicles(vehicles);
    setNumberOfUnassigned(unassignedJobs);
    setNumberOfAssigned(getAssignedJobs(plan, fleet, unassignedJobs));
    setTotalNumberOfVehicles(totalVehicles);
  }, [
    setNumberOfAssigned,
    setNumberOfUnassigned,
    tours,
    unassigned,
    fleet,
    uploaded,
    tourPlanner,
    plan,
  ]);

  const onClickDownloadAssigned = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      const ordersDispatched = getTotalJobsInTours(tours);
      const info = {
        dispatchMode: 'csv-all',
        origin: 'solution details',
        tours: size(tours),
        ordersInTour: ordersDispatched,
        isDemo: user.isLastOrdersAddedDemo,
      };

      AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DISPATCH, info);
      handleIncreaseUsage({ ordersDispatched, toursDispatched: size(tours), mode: 'csv-all' });

      exportToursToCSV(tours, request, tourPlanner, user, initialTourIndex);
    },
    [tours, tourPlanner, user, request, handleIncreaseUsage],
  );

  const onClickDownloadUnassigned = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      const info = {
        assignedOrders: numberOfAssigned,
        unassignedOrders: numberOfUnassigned,
      };

      if (!isEmpty(unassigned)) {
        const grouped = {};
        unassigned.forEach((item) => {
          item.reasons.forEach((reason) => {
            if (!getSafeValue(grouped, reason.code)) setSafeValue(grouped, reason.code, []);
            getSafeValue(grouped, reason.code).push(item);
          });
        });
        keys(grouped).forEach((key) => {
          setSafeValue(info, `unassignedOrdersCode${key}`, size(getSafeValue(grouped, key)));
        });
      }

      AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DOWNLOAD_UNASSIGNED, info);
      saveUnassignedToCSVFile(unassigned, orders, user);
    },
    [orders, unassigned, user, numberOfUnassigned, numberOfAssigned],
  );

  const handleOnClickView = useCallback(
    (forceValue) => {
      if (!onChangeView) return;
      const newValue = forceValue !== undefined ? forceValue : !isToursView;
      onChangeView(newValue);
    },
    [isToursView],
  );

  const vehiclesText = showTotalVehicles
    ? numberOfVehicles
    : `${numberOfVehicles} ${preposition} ${totalNumberOfVehicles}`;
  const vehicleTypeCount = getVehicleTypeCount(
    tourPlanner,
    typeCount,
    fleet,
    totalNumberOfVehicles,
  );

  const vehiclePopupIconStyle = (type) => css`
    position: relative;
    width: ${StyledVehicleIconSize};
    height: ${StyledVehicleIconSize};
    background-size: ${StyledVehicleIconSize} ${StyledVehicleIconSize};
    background-image: url(${getFleetTypeIcon(type)});
    margin-right: 0.3rem;
    margin-left: 0.5rem;
    top: 0.4rem;
  `;

  useEffect(() => {
    setTotalDistance(
      parseInt(
        convertCostsFromTourPlanner({ distance: statistic.distance, isImperial }).distance,
        10,
      ),
    );
  }, [setTotalDistance, user.distance, statistic.distance]);

  return (
    <StyledSummary>
      <SummaryRow
        gridValue={totalCnsmp || totalCO2Emission ? '1.2fr 1.2fr 1fr 1fr 1fr' : '1fr 1fr 1fr'}
      >
        <SummaryElement
          label={distanceTrans}
          text={`${totalDistance} ${isImperial ? mile : kilo}`}
        />
        <SummaryElement label={durationTrans} text={totalTime} />
        <SummaryElement
          label={costTrans}
          text={formatPriceWithCurrency(Math.round(totalCost), user.currency, 0)}
        />
        {totalCnsmp && (
          <SummaryElement
            label={consumptionTransTotal}
            text={totalCnsmp}
            title={`${consumptionExplTrans}${consumptionExplUnits}`}
            textTitle={totalCnsmp}
          />
        )}
        {totalCO2Emission && (
          <SummaryElement
            label={co2Trans}
            text={totalCO2Emission}
            title={co2Explanation}
            textTitle={totalCO2Emission}
          />
        )}
      </SummaryRow>
      <SummaryRow gridValue={totalCO2Emission || totalCnsmp ? '1.1fr 1.4fr' : '0.7fr 1.4fr'}>
        {numberOfAssigned > 0 && (
          <StyledDetailContainer noMarginBottom>
            <StyledWrapper data-test-id="totalAssignedVehicles" typeCount={typeCount.length}>
              <StyledTitle>{vehicleTypes}</StyledTitle>
              {showPopup && (
                <Popup
                  h2={vehicleTypes}
                  text={vehiclesText}
                  items={vehicleTypeCount}
                  icon={vehiclePopupIconStyle}
                  setShowPopup={setShowPopup}
                />
              )}
              <>
                <StyledText>{vehiclesText}</StyledText>
                {typeCount.slice(0, 2).map((ul, idx) => (
                  <StyledText key={idx} small>
                    <StyledSpan title={ul.name}>{`${ul.count} ${preposition} ${ul.id}`}</StyledSpan>
                    <br />
                    <StyledSpan
                      data-test-id="expandMoreVehicles"
                      id="info"
                      title={clickForInfo}
                      onClick={() => setShowPopup(!showPopup)}
                    >
                      {typeCount.length > 2 && idx === 1 ? ' more...' : ''}
                    </StyledSpan>
                  </StyledText>
                ))}
              </>
            </StyledWrapper>
          </StyledDetailContainer>
        )}
        {numberOfAssigned > 0 && (
          <StyledDetailContainer noMarginBottom>
            <div>
              <StyledTitle>{jobTrans}</StyledTitle>
              <StyledText>
                {numberOfAssigned} {preposition} {numberOfAssigned + numberOfUnassigned}
              </StyledText>
            </div>
            {canDownloadCSV() && (
              <StyledDownloadContainer noMarginTop>
                <StyledReview id="button-download-assigned">
                  {numberOfAssigned > 0 && (
                    <>
                      <StyledIconWrapper
                        icon={isToursView ? unassignedJobIconStyle : jobIconStyle}
                        mini
                        id="button-view-unassigned"
                        title={isToursView ? unassignedView : toursView}
                        onClick={() => handleOnClickView()}
                      >
                        <StyledIcon icon={ViewIconStyle(isToursView)} mini />
                      </StyledIconWrapper>
                      <StyledIconWrapper
                        icon={unassignedJobIconStyle}
                        mini
                        id="button-download-unassigned"
                        title={unassignedDownloadTitle}
                        onClick={onClickDownloadUnassigned}
                      >
                        <StyledIcon icon={DownloadIcon} mini />
                      </StyledIconWrapper>
                    </>
                  )}
                  <StyledIconWrapper
                    icon={jobIconStyle}
                    mini
                    id="button-download-assigned"
                    title={`${download} CSV`}
                    onClick={onClickDownloadAssigned}
                  >
                    <StyledIcon icon={DownloadIconContrastStyle} mini />
                  </StyledIconWrapper>
                </StyledReview>
              </StyledDownloadContainer>
            )}
          </StyledDetailContainer>
        )}
      </SummaryRow>
    </StyledSummary>
  );
};

export default withTranslation(SummaryWithDownload);
